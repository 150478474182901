import { urlServer } from "../config/apiConfig";

export const userAuth = async credenciales => {
    let formData = new FormData();
    for ( var key in credenciales ) {
        formData.append(key, credenciales[key]);
    }
    return await fetch(`${ urlServer }/user/auth`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

export const getDatos = async (ruta, parametros) => {
    let formData = new FormData();
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}

export const mutarDatos = async ({ ruta, parametros }) => {
    let formData = new FormData();
    for ( var key in parametros ) {
        formData.append(key, parametros[key]);
    }
    return await fetch(`${ urlServer }${ ruta }`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .catch((error) => console.log(error))
}