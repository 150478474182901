import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import moment from 'moment/moment';
import { getDatos } from '../api/fetchsApiQuerys';
import contactIcon from '../assets/images/contact_icon-01.png';
import ChatContainer from './ChatContainer';
import Vistos from './Vistos';
import Icons from '../components/Icons';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import InfiniteScroll from 'react-infinite-scroll-component';

function CardContacto({ nombre, celular, mensajes, mostrarchats }) {
    const ultimoMensaje = mensajes[0];
    const nombreCliente = (nombre !== '') ? nombre : celular;
    const textoUltMensaje = (ultimoMensaje.texto.length >= 30) ? ultimoMensaje.texto.substring(0, 30) + ' ...' : ultimoMensaje.texto;

    function fechaUltimoMensaje(date) {
        let fechaHoy = moment().format('DD/MM/YYYY');
        let fechaMens = moment(date).format('DD/MM/YYYY');
        if (fechaHoy === fechaMens) {
            return moment(date).format('HH:mm');
        } else {
            if ((Date.now() - (Date.parse(ultimoMensaje.fecha))) > 172800000) {
                return fechaMens;
            } else {
                return 'Ayer';
            }
        }
    }

    function handleClick(e) {
        for (const item of document.querySelectorAll('[name="contact-item"]')) {
            if (item.classList.contains('cont-contacts-list-active')) {
                item.classList.remove('cont-contacts-list-active');
            }
        }
        e.target.closest('[name="contact-item"]').classList.add('cont-contacts-list-active');
        mostrarchats(mensajes, nombre);
    }

    return (
        <div name="contact-item" className="cont-contacts-list" onClick={(e) => handleClick(e)}>
            <img src={contactIcon} alt="foto de perfil" />
            <div className="cont-contacto-info">
                <div className="cont-info-head">
                    <h3>{nombreCliente}</h3>
                    <span>{fechaUltimoMensaje(ultimoMensaje.fecha)}</span>
                </div>
                <div className="cont-info-footer">
                    <Vistos estado={ultimoMensaje.estado} />
                    <p>{textoUltMensaje}</p>
                </div>
            </div>
        </div>
    );
}

export default function BodyChatsDisplay() {

    const user = JSON.parse(localStorage.getItem('userData'))
    const [mensajes, setMesnsajes] = useState([])
    const [show, setMostrarChats] = useState(false);
    const [contacto, setContacto] = useState(null);
    const [mostrarChatMobile, setMostrarChatMobile] = useState(false);
    const [search, setSearch] = useState('')

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    const { data: data = { pages: [{ mensajes: [] }] }, isLoading, fetchNextPage, hasNextPage, isError, error } = useInfiniteQuery(
        ['conversaciones', search], // Clave de la consulta

        ({ pageParam = 1 }) => getDatos('/bot-whatsapp/conversaciones', { token: user.token, asesor: '', init: pageParam, limit: 30, search: search, s: idBase64 }),

        {
            getNextPageParam: (lastPage) => {
                if (lastPage.pagina === lastPage.total) return false
                return lastPage.pagina + 1
            }
        }

    );

    const chats = data?.pages.reduce((prevChats, chat) => prevChats.concat(chat.mensajes), []) ?? []

    function mostrarChats(msjs, contacto) {
        if (show === false) setMostrarChats(true);
        setMesnsajes(msjs);
        setContacto(contacto);
        if (isMobile) {
            //console.log('mostrar chats mobile')
            setMostrarChatMobile(true);
        }
    }

    function mostrarContactos() {
        setMostrarChatMobile(false);
    }

    return (
        <main className="main-chats">
            {
                !isMobile ?
                    <>
                        <div className="chats-iz" >
                            <div className="cont-search-chat">
                                <input type="text" placeholder="buscar" onChange={(e) => setSearch(e.target.value)} />
                            </div>
                            {isError && <div>Error: {error}</div>}
                            <InfiniteScroll
                                dataLength={chats.length} //This is important field to render the next data
                                next={() => fetchNextPage()}
                                hasMore={chats.length > 0 && (hasNextPage || isLoading)}
                                loader={<ThreeDots
                                    visible={hasNextPage}
                                    height="10"
                                    width="100%"
                                    color="#004CF2"
                                    radius="5"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{ marginTop: '20px', marginBottom: '20px' }}
                                    wrapperClass=""
                                />}
                                height={'calc(100vh - 70px)'}>
                                {
                                    chats.map((contacto) => (
                                        <CardContacto
                                            key={contacto.id}
                                            nombre={contacto.nombres}
                                            celular={contacto.celular}
                                            mensajes={contacto.todos_mensajes}
                                            mostrarchats={(msjs, cont) => mostrarChats(msjs, cont)}
                                        />
                                    ))}
                                {

                                }
                            </InfiniteScroll>
                        </div>
                        <ChatContainer contactIcon={contactIcon} mostrarchats={show} mensajes={mensajes} contacto={contacto} mostrarContactos={null} />
                    </>
                    :
                    <>
                        {!mostrarChatMobile ?
                            <div className="chats-iz">
                                <div className="cont-search-chat">
                                    <input type="text" placeholder="buscar" onChange={(e) => setSearch(e.target.value)} />
                                </div>
                                {isError && <div>Error: {error}</div>}
                                {
                                    <InfiniteScroll
                                        dataLength={chats.length} //This is important field to render the next data
                                        next={() => fetchNextPage()}
                                        hasMore={chats.length > 0 && (hasNextPage || isLoading)}
                                        loader={<ThreeDots
                                            visible={hasNextPage}
                                            height="10"
                                            width="100%"
                                            color="#004CF2"
                                            radius="5"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{ marginTop: '20px', marginBottom: '20px' }}
                                            wrapperClass=""
                                        />}
                                        height={'calc(100vh - 70px)'}>
                                        {
                                            chats.map((contacto) => (
                                                <CardContacto
                                                    key={contacto.id}
                                                    nombre={contacto.nombres}
                                                    celular={contacto.celular}
                                                    mensajes={contacto.todos_mensajes}
                                                    mostrarchats={(msjs, cont) => mostrarChats(msjs, cont)}
                                                />
                                            ))}
                                        {

                                        }
                                    </InfiniteScroll>
                                }
                            </div> :
                            <ChatContainer contactIcon={contactIcon} mostrarchats={show} mensajes={mensajes} contacto={contacto} mostrarContactos={() => mostrarContactos()} />
                        }
                    </>
            }
            { /*isLoading &&
                <div id="loading-mgs">
                    <p>Cargando mensajes {porcentaje}%</p>
                    <div className="barra">
                        <div className="cont-percent" style={{ width: `${porcentaje}%` }}></div>
                    </div>
                </div>
            */}
        </main>
    );
}