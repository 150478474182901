import { customSwall } from "../components/CustomSwal";
import { urlServer } from "../config/apiConfig";

export const validToken = async token => {

    const errors = (e) => {
        switch (e) {
            case 'Expired token':
                return 'La sesión a expirado, vuelve a ingresar tus datos'
            default:
                return e
        }
    }

    const reload = error => {
        if (error) {
            customSwall.fire({
                title: 'Error!',
                text: errors(error),
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(result => {
                window.localStorage.removeItem('userData');
                window.location.reload()
            });
        } else {
            window.localStorage.removeItem('userData');
            window.location.reload()
        }
    }

    let isValid = false;

    let formData = new FormData();
    formData.append('token', token);
    await fetch(`${ urlServer }/user/validate-token`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
        if (data.ok) {
            isValid = data.ok 
        } else {
            reload(data.error)
        }
    })
    .catch(error => {
        reload()
    })
}