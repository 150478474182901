import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { getDatos, mutarDatos } from '../api/fetchsApiQuerys';
import Icons from "../components/Icons"
import Spiner from "../components/Spiner";
import { customSwall } from "../components/CustomSwal";
import { MessagePrew } from "./MessagePrew";
import moment from "moment";

export default function ListaRespuestas() {
    const user = JSON.parse(localStorage.getItem('userData'))
    const tipos = { text: 'Texto', button: 'Botones', list: 'Lista', image: 'Imagen', video: 'Video', audio: 'Audio', sticker: 'Stiker', contacts: 'Contacto', location: 'Ubicación' }
    const [ mt, setMt ] = useState(false);
    const [ preview, setPreview ] = useState({})
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    const { data: { flows: flowsList } = {}, isFetching } = useQuery(["flows"], () => getDatos('/bot-whatsapp/flows', { token: user.token, s: idBase64 }), {
        refetchOnWindowFocus: true,
        retryDelay: 1000
    })

    const { mutate, isLoading: isMutating } = useMutation(mutarDatos, {
        onSuccess: (data) => {
            if (data.ok) {
                queryClient.invalidateQueries(["flows"])
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'ocurrio un error',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    function borrar(keyFlow) {
        customSwall.fire({
            title: 'Eliminar Respuesta',
            text: 'Esta acción no se puede revertir, asegurate de que esta respuesta no esté en la parte alta del arbol de respuestas',
            icon: 'question',
            confirmButtonText: 'Eliminar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        }).then(r => {
            if (r.isConfirmed) {
                mutate({ ruta: '/bot-whatsapp/flows/eliminar', parametros: { token: user.token, flowId: keyFlow, s: idBase64 } })
            }
        })
    }

    function editar(keyFlow) {
        navigate(`/bot-whatsapp/${ idBase64 }/agregar-modificar-respuesta?idf=${ keyFlow }`)
    }

    function cerrarPrew() {
        setPreview({})
        setMt(false)
    }

    function mostrarPrew(flow) {
        let text = JSON.parse(flow.text)
        setPreview({
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: text.texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot",
            beforeAfter: getBeforeAfter(JSON.parse(flow.before_after))
        })
        setMt(true)
    }

    function getMsg(flowId) {
        if (flowId  === 0) {
            return {}
        }
        let flow = flowsList[flowId]
        return !!flow ? {
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: JSON.parse(flow.text).texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot",
            beforeAfter: {
                before: {
                    value: false,
                    message: ''
                },
                after: {
                    value: false,
                    message: ''
                }
            }
        } : {}
    }

    function getBeforeAfter(bA) {
        return { 
            before: {
                value: bA.before > 0,
                message: bA.before > 0 ? getMsg(bA.before) : {}
            },
            after: {
                value: bA.after > 0,
                message: bA.after > 0 ? getMsg(bA.after) : {}
            }
        }
    }

    return(<main className="main-chats colum">
        { (isFetching || isMutating) && <Spiner/> }
        <div className="ans-cont-flows">
            <div className="columna-completa cont-cab-flows">
                <h2>Lista de Respuestas</h2>
                <div className="ans-cont-options">
                    <Link to={`/bot-whatsapp/${ idBase64 }/agregar-modificar-respuesta`}  className='btn btn-primario'><Icons req={ 'chat' } fill="var(--blanco)" height="1.2em"/>Agregar</Link>
                    <Link to={`/bot-whatsapp/${ idBase64 }/arbol-del-bot`} className='btn btn-secundario'><Icons req={ 'chat' } fill="var(--blanco)" height="1.2em"/> Arbol del Bot</Link>
                </div>
            </div>
            <ul className="columna-completa cont-cab-tabla-flows tabla-flows-header">
                <li className="fc1">#</li>
                <li className="fc2">Tipo</li>
                <li className="fc3">Nombre</li>
                <li className="fc4">Acciones</li>
            </ul>
        </div>
        {
            mt && <MessagePrew cerrarPrew={ () => cerrarPrew() } message={ preview } beforeAfter={ preview.beforeAfter }/>
        }
        <ul className="columna-completa cont-listas-flow cont-cab-tabla-flows">
            {!!flowsList ? Object.values(flowsList)?.map((f) => {
                return(<li key={ f.id } className="li-flows-list">
                <div className="fc fc1">{ f.id }</div>
                <div className="fc fc2">{ tipos[f.type] }</div>
                <div className="fc fc3">{ f.name }</div>
                <div className="fc fc4 fc-cont-acciones">
                    <div onClick={ () => mostrarPrew(f) }><Icons req={ 'eye' } fill="var(--negro)" /></div>
                    <div onClick={ () => editar(f.id) }><Icons req={ 'edit' } fill="var(--negro)" /></div>
                    <div onClick={ () => borrar(f.id) }><Icons req={ 'delete' } fill="var(--negro)" /></div>
                </div>
            </li>)
            }) : <p>No se han creado respuestas</p>}
        </ul>
    </main>)
}