import Spiner from "../components/Spiner";
import { getDatos, mutarDatos } from "../providers/dataServer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customSwall } from "../components/CustomSwal";
import ModalBox from "../components/ModalBox";
import { useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { MessagePrew } from "./MessagePrew";
import Icons from "../components/Icons";

function ElementActivadorConfig({ id, cerrar, defaultValues }) {

    const user = JSON.parse(localStorage.getItem('userData'))
    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())
    const [ flowSelected, setFlowSelected ] = useState(null)
    const [ mt, setMt ] = useState(false);
    const { data: { flows: flowsList } = { flows: [] } } = useQuery(["flows"], () => getDatos('/bot-whatsapp/flows', { token: user.token, s: idBase64 }), {
        refetchOnWindowFocus: false,
        staleTime: 'Infinity',
        retryDelay: 1000, 
        onSuccess: (data) => {
            if (id !== 0) {
                setFlowSelected(data.flows[defaultValues.respuestaId])
            }
        }})

    const metods = useForm({
        defaultValues: {
            id: id ?? 0,
            palabras: defaultValues.palabras ?? '',
            type: defaultValues.type ?? 2, // 2 = texto, 1 = plantilla
            respuesta: defaultValues.respuestaId ?? ''
        },
    })
    const { formState: { errors } } = metods;

    const { mutate : guardar, isLoading: guardando } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/bot-whatsapp/guardarActivadores', parametros: datos})
    })

    function cerrarPrew() {
        setFlowSelected(null)
        setMt(false)
    }

    function mostrarPrew(flow) {
        let text = JSON.parse(flow.text)
        setFlowSelected({
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: text.texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot",
            beforeAfter: getBeforeAfter(JSON.parse(flow.before_after))
        })
        setMt(true)
    }

    function getMsg(flowId) {
        if (flowId  === 0) {
            return {}
        }
        let flow = flowsList[flowId]
        return !!flow ? {
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: JSON.parse(flow.text).texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot",
            beforeAfter: {
                before: {
                    value: false,
                    message: ''
                },
                after: {
                    value: false,
                    message: ''
                }
            }
        } : {}
    }

    function getBeforeAfter(bA) {
        return { 
            before: {
                value: bA.before > 0,
                message: bA.before > 0 ? getMsg(bA.before) : {}
            },
            after: {
                value: bA.after > 0,
                message: bA.after > 0 ? getMsg(bA.after) : {}
            }
        }
    }

    function onSubmit(datos) {
        datos['s'] = idBase64
        guardar(datos, {
            onSuccess: (data) => {
                if (data.ok) {
                    queryClient.invalidateQueries(["activadores"])
                    cerrar()
                } else {
                    customSwall.fire({
                        icon: 'error',
                        title: 'Error',
                        text: !!data.error ? data.error : 'no se pudo guardar',
                        showConfirmButton: true,
                    })
                }
            },
        })
    }
    
    return <form className="ans-formulario formulaio-activadores" onSubmit={ metods.handleSubmit(onSubmit) }>
        {
            mt && <MessagePrew cerrarPrew={ () => cerrarPrew() } message={ flowSelected } beforeAfter={ flowSelected.beforeAfter }/>
        }
        { guardando && <Spiner/> }
        <div className="cont-text-form">
            <label htmlFor="palabras">{`Lista de palabras (separadas por coma)`}:</label>
            <textarea type="text"  placeholder="Hola, Buenos días, Holi, ola" {...metods.register("palabras", {
                    required: {
                        value: true,
                        message: 'Debes colocar por lo menos una palabra'
                    },
                    minLength: {
                        value: 3,
                        message: 'El palabras debe tener mínimo 3 caracteres'
                    }
                })} ></textarea>
            <span>{ !!errors['palabras'] && errors['palabras'].message }</span>
        </div>
        <div className="cont-before-after">
            <div className="cont-select-type-form">
                <label htmlFor="before">Tipo de respuesta:</label>
                <select {...metods.register("type", {
                    //onChange: e => setBefore(parseInt(e.target.value))
                })}>
                    <option value={ 2 }>Respuesta a Texto</option>
                    <option value={ 1 }>Respuesta a Plantilla</option>
                </select>
            </div>
            <div className="cont-select-type-form">
                <label htmlFor="after">{`Respuesta a esta(s) palabra(s)`}</label>
                <select {...metods.register("respuesta", {
                    onChange: e => setFlowSelected(flowsList[e.target.value]),
                    required: {
                        value: true,
                        message: 'Elige la respuesta para estas palabras'
                    }
                })}>
                    <option value={ '' }>Ninguno</option>
                    {
                        Object.values(flowsList)?.map((f) => <option key={ f.id } value={ f.id }>{ f.name }</option>)
                    }
                </select>
                <span>{ !!errors['respuesta'] && errors['respuesta'].message }</span>
            </div>
        </div>
        { (metods.watch('respuesta') !== '') && <div className="btn btn-prev-activadores" onClick={ () => mostrarPrew(flowSelected) }>
            <Icons req={ 'eye' } fill="var(--negro)" />
            <p>Previsualizar</p>
        </div>}
        <button className="btn btn-verde">Guardar</button>
    </form>
}

export default function TextosActivadores() {

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())
    const [ mostarModalBox, setMostrarModalBox ] = useState(false)
    const [ activadorSelected, setActivadorSelected ] = useState(0)
    const [ activadorObjectModify, setActivadorObjectModify ] = useState({})
    const { data: activadores = [], isLoading } = useQuery(["activadores"], () => getDatos('/bot-whatsapp/getActivadores', { s: idBase64 }))

    const [ flowSelected, setFlowSelected ] = useState(null)
    const [ mt, setMt ] = useState(false);

    const { data: { flows: flowsList } = { flows: [] } } = useQuery(["flows"], () => getDatos('/bot-whatsapp/flows', { s: idBase64 }), {
        refetchOnWindowFocus: false,
        staleTime: 'Infinity',
        retryDelay: 1000, 
    })

    const { mutate : eliminar, isLoading: isEliminando } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/bot-whatsapp/eliminarActivador', parametros: datos})
    })

    function eliminarActivador(id) {
        customSwall.fire({
            icon: 'warning',
            title: `Eliminar Activador?`,
            text: `Estás segur@, esta acción no se puede revertir`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, Eliminar'
        }).then( response => {
            if (response.isConfirmed) {
                eliminar({ id: id, s: idBase64 }, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.invalidateQueries(["activadores"])
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'no se pudo eliminar',
                                showConfirmButton: true,
                            })
                        }
                    },
                })
            }
        })
    }

    function cerrarPrew() {
        setFlowSelected(null)
        setMt(false)
    }

    function mostrarPrew(flow) {
        let text = JSON.parse(flow.text)
        setFlowSelected({
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: text.texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot",
            beforeAfter: getBeforeAfter(JSON.parse(flow.before_after))
        })
        setMt(true)
    }

    function getMsg(flowId) {
        if (flowId  === 0) {
            return {}
        }
        let flow = flowsList[flowId]
        return !!flow ? {
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: JSON.parse(flow.text).texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot",
            beforeAfter: {
                before: {
                    value: false,
                    message: ''
                },
                after: {
                    value: false,
                    message: ''
                }
            }
        } : {}
    }

    function getBeforeAfter(bA) {
        return { 
            before: {
                value: bA.before > 0,
                message: bA.before > 0 ? getMsg(bA.before) : {}
            },
            after: {
                value: bA.after > 0,
                message: bA.after > 0 ? getMsg(bA.after) : {}
            }
        }
    }

    function cerrarModal() {
        setMostrarModalBox(false)
        setActivadorSelected(0)
        setActivadorObjectModify({})
    }

    function modificarActivador(id) {
        setActivadorSelected(id)
        setMostrarModalBox(true)
        setActivadorObjectModify(activadores[id])
    }

    return <div className="cont-lista-formularios">
        {(isLoading || isEliminando) && <Spiner/>}
        { mostarModalBox && <ModalBox close={ () => cerrarModal() } title={ activadorSelected === 0 ? 'Nuevo Activador' : 'Modificar Activador' }>

            <ElementActivadorConfig cerrar={ () => cerrarModal() } id={ activadorSelected } defaultValues={ activadorObjectModify }/>
        </ModalBox>}
        {
            mt && <MessagePrew cerrarPrew={ () => cerrarPrew() } message={ flowSelected } beforeAfter={ flowSelected.beforeAfter }/>
        }
        <div className="columna-completa header-formularios">
            <h1>Activadores</h1>
            <div className="cont-actions-header-forms">
                <button className="btn btn-azul" onClick={ () => setMostrarModalBox(true) }>Nuevo Activador</button>
            </div>
        </div>
        <div className="cont-tabla-forms">
            <table id="tabla-productos" className="tabla-productos tabla-activadores">
                <thead>
                    <tr>
                        <th width={'20px'} >#</th>
                        <th>Palabras</th>
                        <th>Tipo</th>
                        <th>Respuesta</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.values(activadores)?.map((activador, index) => (
                            <tr key={activador.id}>
                                <td width={'20px'}>{index + 1}</td>
                                <td >{ activador.palabras }</td>
                                <td >{ activador.type === 2 ? 'Texto' : 'Plantilla' }</td>
                                <td >{ activador.respuesta }</td>
                                <td className='td-acciones'>
                                    <button className="btn btn-azul" onClick={ () => mostrarPrew(flowsList[activador.respuestaId]) }>
                                        <Icons req={ 'eye' } fill="var(--blanco)" />
                                    </button>
                                    <button onClick={() => modificarActivador(activador.id) } className="btn btn-azul">Modificar</button>
                                    <button onClick={() => eliminarActivador(activador.id)} className="btn btn-rojo">Eliminar</button>
                                </td>
                            </tr>
                        )) ?? <p>No hay datos</p>}
                </tbody>
            </table>
        </div>
    </div>
}