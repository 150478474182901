import { useEffect, useState } from "react"
import '../assets/css/answers.css';
import Icons from "../components/Icons";
import { MessagePrew } from "./MessagePrew";
import moment from "moment";

export default function ContAnswer({ keyWords, flowPadre, keyWord, interId, nomRes, flowList, color='var(--gris-ultra-claro)', modificarArbol }) {
    const [ flow, setFlow ] = useState(null)
    const [ selected, setSelected ] = useState('Ninguno')
    const [ mostrarHijos, setMostrarHijos ] = useState(false)
    const [ preview, setPreview ] = useState({})
    const [ mt, setMt ] = useState(false)

    useEffect(() => {
        if (!!keyWord) {
            let flowId = keyWords[keyWord] ?? 0;
            if (flowId > 0) {
                setFlow(flowList[flowId])
                setSelected(flowId)
            }
        }
    },[keyWord])

    function setFlowSelect(e) {
        var element = {}
        if (e.target.value !== 'Ninguno') {
            setFlow(flowList[e.target.value])
            element[keyWord] = flowList[e.target.value].id;
        } else {
            element[keyWord] = 0;
            setFlow(null)
        }
        modificarArbol(element)
        setSelected(e.target.value)
    }
    function Hijos({ flowPadre, flowList, modificarArbol }) {
        let contRespuestas = <></>
        const type = flowPadre.type

        function getColorBg() {
            let difference = 255 - 215;
            let rand = Math.random();
            rand = Math.floor( rand * difference);
            rand = rand + 235;
            return rand;
        }    

        switch (type) {
            case 'list':
                let lista = JSON.parse(flowPadre.list)
                contRespuestas = <>{
                    lista.sections?.map((section) => {
                        return (
                        section.rows?.map((s) => {
                            return <ContAnswer 
                                key={ s.id }
                                keyWords={ keyWords }
                                keyWord={ s.id }
                                flowPadre={ flowPadre }
                                interId={ s.id }
                                modificarArbol={ modificarArbol }
                                nomRes={ s.title }
                                flowList={ flowList }
                                color={ `rgb(${ getColorBg() } ${ getColorBg() } ${ getColorBg() })` }
                            />
                        }))
                    })
                }</>
                break
            case 'button':
                let buttons = JSON.parse(flowPadre.buttons)
                contRespuestas = <>{
                    buttons?.map((b) => {
                        return (
                            <ContAnswer
                                keyWords={ keyWords }
                                keyWord={ b.id }
                                key={ b.id }
                                flowPadre={ flowPadre }
                                interId={ b.id }
                                modificarArbol={ modificarArbol }
                                nomRes={ b.text }
                                flowList={ flowList }
                                color={ `rgb(${ getColorBg() } ${ getColorBg() } ${ getColorBg() })` }
                            />
                        )
                    })
                }</>
                break
            case 'text':
                let requerido = JSON.parse(flowPadre.requerido)
                contRespuestas =  <ContAnswer keyWords={ keyWords } keyWord={ requerido.req_texto } flowPadre={ flowPadre } modificarArbol={ modificarArbol } nomRes={ flowPadre.name } flowList={ flowList } color={ `rgb(${ getColorBg() } ${ getColorBg() } ${ getColorBg() })` }/>
                break
        }
        return contRespuestas
    }

    function mostrarH() {
        if (!!flow) {
            setMostrarHijos(mostrarHijos ? false : true)
        } else {
            setMostrarHijos(false)
        }
    }

    function cerrarPrew() {
        setPreview({})
        setMt(false)
    }

    function mostrarPrew() {
        let text = JSON.parse(flow.text)
        setPreview({
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: text.texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot"
        })
        setMt(true)
        console.log(flow)
    }

    function getMsg(flowId) {

        if (flowId  === 0) {
            return {}
        }

        let flow = flowList[flowId]
        return !!flow ? {
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: JSON.parse(flow.text).texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot"
        } : {}
    }

    function getBeforeAfter(bA) {
        let b = { 
            before: {
                value: bA.before > 0,
                message: getMsg(bA.before)
            },
            after: {
                value: bA.after > 0,
                message: getMsg(bA.after)
            }
        }
        return b
    }

    return(<>
        {
            mt && <MessagePrew cerrarPrew={ () => cerrarPrew() } message={ preview } beforeAfter={ getBeforeAfter(JSON.parse(flow.before_after)) }/>
        }
        <div className="cont-msg-ans" style={ { backgroundColor: color } }>
            <h4>{ nomRes }</h4>
            <select onChange={ setFlowSelect } value={ selected }>
                <option value={ 'Ninguno' }>Elige una respuesta</option>
                {
                    Object.values(flowList)?.map((f) => {
                        return <option key={ f.id } value={ f.id }>{ f.name }</option>
                    })
                }
            </select>
            <p>Tipo: { !!flow ? flow.type : 'Ninguno' }</p>
            {
                !!flow && <button className="btn-prev" onClick={ mostrarPrew }><Icons req={ 'eye' } fill="var(--negro)"/> Previsualizar</button>
            }
            { !!flow && (((flow.type === 'button' || flow.type === 'list') || JSON.parse(flow.requerido).is_req === 1) && <button onClick={ mostrarH }><Icons req={ mostrarHijos ? 'flechas_arriba' : 'flechas_abajo' } fill="var(--blanco)" /></button>)  }
            { (mostrarHijos && !!flow) && 
            <div className="ans-cont-iniciales">
                <Hijos flowPadre={ flow } flowList={ flowList } modificarArbol={ modificarArbol }/>
            </div>
            }
        </div>
    </>)
}
