import { useMutation, useQueryClient } from 'react-query'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import ContAnswer from './ContAnswer';
import { mutarDatos } from '../api/fetchsApiQuerys';
import '../assets/css/answers.css';
import Icons from "../components/Icons";
import Spiner from "../components/Spiner";
import { customSwall } from "../components/CustomSwal";

export default function AnswerConfig() {
    const user = JSON.parse(localStorage.getItem('userData'))

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    const [ changesToSend, setChangesToSend ] = useState({});
    const [ hayCambios, setHayCambios ] = useState(false);
    const [ isCheck, setIsCheck ] = useState(false);
    const { 
        data: { 
            flows: flowsList = {}, 
            keyWords,
            config = { registro: false, activo: false } } = {},
        mutate,
        isLoading,
        isSuccess
    } = useMutation(mutarDatos, {
        onSuccess: data => {
            setChangesToSend(data.keyWords)
            setIsCheck(data.config.registro)
        }
    })

    useEffect(() => {
        mutate({ ruta: '/bot-whatsapp/flows', parametros: { token: user.token, s: idBase64 } })
    }, [mutate])

    const { isLoading: guardando, mutate: guardarArbol } = useMutation(mutarDatos, {
        onSuccess: data => {
            customSwall.fire({
                title: 'Correcto',
                text: 'Acción procesada con éxito',
                icon: 'success',
                confirmButtonText: 'Ok'
            }).then(r => mutate({ ruta: '/bot-whatsapp/flows', parametros: { token: user.token, s: idBase64 } }))
        }
    })

    function guardar() {
        console.log(changesToSend)
        guardarArbol({ ruta: '/bot-whatsapp/tree/guardar', parametros: { token: user.token, s: idBase64, tree: JSON.stringify(changesToSend), registro: isCheck ? 1 : 0 } })
    }

    function modificarArbol(data) {
        setHayCambios(true)
        let updateChanges = changesToSend
        updateChanges[Object.keys(data)[0]] = Object.values(data)[0]
        console.log(updateChanges)
        setChangesToSend(updateChanges)
    }

    console.log(config)

    return(<main className="main-chats colum">
        { (isLoading || guardando) && <Spiner/> }
        { !!flowsList ? <>
        <div className="ans-cont-flows">
            <div className="columna-completa cont-cab-flows">
                <h2>Arbol del Bot</h2>
                <div className="ans-cont-options">
                    { hayCambios && <button onClick={ guardar } className='btn btn-primario'>Guardar</button>}
                    <Link to={`/bot-whatsapp/${ idBase64 }/lista-de-respuestas`} className='btn btn-secundario'><Icons req={ 'chat' } fill="var(--blanco)" height="1.2em"/> Lista de Respuestas</Link>
                </div>
            </div>
            <div className="columna-completa cont-config-bot-ar">
                <div className="option-config-bot">
                    <input checked={ isCheck } type="checkbox" onChange={ (e) =>  {
                        config.registro = e.target.checked ? 1 : 0;
                        setHayCambios(true)
                        setIsCheck(e.target.checked)
                    } } />
                    <label>El registro es obligatorio</label>
                </div>
            </div>
        </div>
        <div className="cont-configs-chats-bot">
            <div className="cont-dropables">
                { (Object.keys(flowsList).length > 0) && <div className="cont-initials">
                    <ContAnswer
                    nomRes={ 'Respuesta Registrado' }
                    flowList={ flowsList }
                    modificarArbol={ (data) => modificarArbol(data) }
                    keyWords={ keyWords }
                    keyWord={ 'bienvenida' }
                    
                    />
                    <ContAnswer
                    nomRes={ 'Respuesta No Registrado' }
                    flowList={ flowsList }
                    modificarArbol={ (data) => modificarArbol(data) }
                    keyWords={ keyWords }
                    keyWord={ 'bienvenida_n_r' }
                    />
                    <ContAnswer
                    nomRes={ 'Respuesta no encontrada' }
                    flowList={ flowsList }
                    modificarArbol={ (data) => modificarArbol(data) }
                    keyWords={ keyWords }
                    keyWord={ 'no_info' }
                    />
                </div>}
            </div>
        </div>
        </> : ( isSuccess && <h1>No se puede recuperar la información, intenta nuevamente o contacta a soporte</h1>) }
    </main>)
}