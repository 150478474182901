import { useMutation, useQuery } from "react-query"
import { getDatos, mutarDatos } from "../providers/dataServer"
import Spiner from "../components/Spiner"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import '../assets/css/answers.css';

export default function AgregarModificarFormulario() {

    const navigate = useNavigate()

    const user = JSON.parse(localStorage.getItem('userData'))
    useQuery(["flowsList"], () => () => getDatos('/bot-whatsapp/getFlowList'), {
        refetchOnWindowFocus: true,
        enabled: true, // disable this query from automatically running
        retryDelay: 2000,
    });

    const { mutate: guardarNuevo, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/bot-whatsapp/newFormulario', parametros: datos })
    })

    const metods = useForm({
        defaultValues: {

        },
        shouldUnregister: false
    })
    const { formState: { errors, isValid, isDirty } } = metods;

    return <div className="cont-lista-formularios">
        {isLoading && <Spiner />}
        <div className="columna-completa header-formularios">
            <h1>Nuevo Formulario</h1>
            <div className="cont-actions-header-forms">
                <button className="btn btn-azul" onClick={() => navigate('/bot-whatsapp/agregar-modificar-formulario')}>Guardar</button>
                <button className="btn btn-rojo" onClick={() => navigate(-1)}>Cancelar</button>
            </div>
        </div>
        <div className="cont-tabla-forms">
            <form>
                <div className="cont-select-name">
                    <div className="cont-select-type-form">
                        <label htmlFor="type">Tipo de respuesta:</label>
                        <select {...metods.register("type")}>
                            <option value={'text'}>Texto</option>
                            <option value={'button'}>Botones</option>
                            <option value={'list'}>Lista</option>
                            <option value={'image'}>Imagen</option>
                            <option value={'video'}>Video</option>
                            <option value={'audio'}>Audio</option>
                            <option value={'document'}>Documento</option>
                            <option value={'location'}>Ubicación</option>
                            <option value={'contacts'}>Contacto</option>
                        </select>
                    </div>
                    <div className="cont-nombre-msg-form">
                        <label htmlFor="nombre">Nombre del mensaje:</label>
                        <input type="text" {...metods.register("name", {
                            required: {
                                value: true,
                                message: 'El nombre de esta respuesta es obligatorio'
                            },
                            minLength: {
                                value: 5,
                                message: 'El nombre debe tener mínimo 5 caracteres'
                            },
                            maxLength: {
                                value: 50,
                                message: 'El nombre debe tener máximo 50 caracteres'
                            }
                        })} placeholder="Ejemplo: Respuesta Servicio Técnico" />
                        <span>{!!errors['name'] && errors['name'].message}</span>
                    </div>
                </div>
            </form>
        </div>
    </div>
}