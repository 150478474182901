import { Navigate } from "react-router-dom"
import { customSwall } from "./CustomSwal";
import { validToken } from "../api/validarDatos";
import moment from "moment/moment";

export const ProtectedRoutes = ({ children, redirecTo='/' }) => {
    const user = !!localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    if (!user) {
        return <Navigate to={ redirecTo }/>;
    }

    validToken(user.token)

    if (children[1]) {
        if (children[1].type.name === 'BotWhatsapp') {
            let estadoServicio = false;
            let date = moment().format('YYYY-MM');
            user.services.forEach(element => {
                if (element.servicio === 'Bot Whatsapp') {
                    date += '-' + (element.dia_renovacion + 5);
                    estadoServicio = element.estado === 1 ? true : false;
                }
            });
            if (!estadoServicio) {
                customSwall.fire({
                    title: 'Servicio Caducado',
                    text: 'Lo sentimos, aun no has renovado la suscripción a este servicio, el bot permanecerá activo hasta el ' + moment(date).format('LL'),
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })
                return <Navigate to={ redirecTo }/>;
            }
        }
    }

    return children;
}