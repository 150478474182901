import { useState } from "react";
import Icons from "../components/Icons";
import { ListView, Location, Image, Video, Sticker, Audio, Document, Contacts } from "./MessageType";
import moment from "moment";
import ModalBox from "../components/ModalBox";
import Vistos from "./Vistos";

function texto(texto) {
    let nuevoTexto = texto
    let textMatchs = nuevoTexto?.match(/\*.+[^\n ]\*/g)
    if (!!textMatchs) {
        textMatchs.forEach(element => {
            let textLimpio = element.substring(1, element.length - 1)
            nuevoTexto = nuevoTexto.replace(element, '<b>' + textLimpio + '</b>')
        });
    }
    let textMatchs2 = nuevoTexto.match(/\_.+[^\n ]\_/g)
    if (!!textMatchs2) {
        textMatchs2.forEach(element => {
            let textLimpio = element.substring(1, element.length - 1)
            nuevoTexto = nuevoTexto.replace(element, '<i>' + textLimpio + '</i>')
        });
    }
    return nuevoTexto
}

function ChatUser({ mensaje, mostrarList }) {
    let media = <></>
    let maxW = mensaje.asesor === 'Bot' ? ' new-width' : ''
    let file = !!mensaje.archivo ? `{"type": ${ mensaje.tipo },"name": ${ mensaje.archivo },"url": ""}` : mensaje.media

    switch(mensaje.tipo) {
        case 'sticker':
            media = <Sticker img={ file }/>
            break
        case 'image':
            media = <Image img={ file }/>
            break
        case 'audio':
            media = <Audio media={ file }/>
            break
        case 'video':
            media = <Video video={ file }/>
            break
        case 'document':
            media = <Document file={ file } nombre={ mensaje.nombre_archivo }/>
            break
        case 'location':
            let location = JSON.parse(mensaje.location)
            media = <Location latitude={ location.latitude } longitude={ location.longitude } />
            break
        case 'contacts':
            media = <Contacts cname={ mensaje.cname } cnumero={ mensaje.cnumero } contact={ !!mensaje.contacto ? mensaje.contacto : '{}' } />
            break
        case 'button':
            let med = JSON.parse(mensaje.media)
            if (med.type === 'image') {
                media = <Image img={ file }/>
            }
            if (med.type === 'video') {
                media = <Video video={ file }/>
            }
            break
        case 'list':
            //console.log(mensaje)
            break
        default:
    }

    function Buttons({ buttons, mostrarList }) {
        return(<div className="buttons-msj" onClick={ mensaje.tipo === 'list' ? mostrarList : null } style={{ width: 350 + 'px' }}>
            { mensaje.tipo === 'button' ?
                buttons.map((button, index) => {
                    return <p key={ `id_${ index }` }>{ button.text }</p>
                }) : <p className='list-element'><Icons req={ 'list' } fill="rgb(0, 157, 226)" /> { buttons.button }</p>
            }
        </div>)
    }

    function isEmpty(obj) {
        for (const prop in obj) {
          if (Object.hasOwn(obj, prop)) {
            return false;
          }
        }
      
        return true;
    }

    return (
        <li className="con-message-my-message">
        {/*<div className={ `cont-message my-message${ maxW }` }>
                { media }
                <div className="new-line" dangerouslySetInnerHTML={{__html: !!mensaje.texto ? texto(mensaje.texto): '' }} />
                { (!isEmpty(JSON.parse(mensaje.buttons)) || !isEmpty(JSON.parse(mensaje.list))) && 
                    <Buttons buttons={ JSON.parse(mensaje.tipo === 'button' ? mensaje.buttons : mensaje.list) } mostrarList={ mostrarList }/>
                }
                <div className="cont-footer-msgs">
                    <span>{ moment(mensaje.fecha).format('HH:mm') }</span>
                    <Vistos estado={mensaje.estado} />
                </div>
            </div>**/}
        <div className={ `cont-message prev` }>
            <div className={`cont-message-content my-message${ maxW }`}>
                { media }
                <div className="new-line" dangerouslySetInnerHTML={{__html: texto(mensaje.texto) }} />
                <div className="cont-footer-msgs">
                    <span>{ moment(mensaje.fecha).format('HH:mm') }</span>
                    <Vistos estado={mensaje.estado} />
                </div>
            </div>
            { (!isEmpty(JSON.parse(mensaje.buttons)) || !isEmpty(JSON.parse(mensaje.list))) && 
                <Buttons buttons={ JSON.parse(mensaje.tipo === 'button' ? mensaje.buttons : mensaje.list) } mostrarList={ mostrarList }/>
            }
        </div>
    </li>
    );
}

export function MessagePrew({ cerrarPrew, message, isModal = true, beforeAfter = { before: { value: false, message: {} }, after: { value: false, message: {} }} }) {
    const [ currentModal, setCurrentModal ] = useState(null);
    const [ mostarModalBox, setMostarModalBox ] = useState(false);

    function closeModalBox() {
        setMostarModalBox(false);
    }

    function funcMostrarModalBox(lista) {
        setMostarModalBox(true);
        setCurrentModal(lista);
    }

    function cerrar() {
        cerrarPrew()
    }

    return (<div className={ isModal ? 'cont-messaje-prew' : 'cont-message-prew-no-modal' }>
        { isModal && <button onClick={ cerrar } className="btn-close-prew"><Icons req={ 'close_x' } fill="var(--blanco)" /></button>}
        { mostarModalBox &&
            <ModalBox close={ () => closeModalBox() } title={ 'CONTENIDO LISTA' } isPreview={ true }>
                <ListView lista={ currentModal }/>
            </ModalBox>
        }
        { beforeAfter.before.value && <>
            <p className="p-pre-pos-msg">Pre Mensaje</p>
            <ChatUser mensaje={beforeAfter.before.message} mostrarList={ () => funcMostrarModalBox(beforeAfter.before.message.list) } /><br/>
        </>}
        <ChatUser mensaje={ message } mostrarList={ () => funcMostrarModalBox(message.list) } />
        { beforeAfter.after.value && <>
            <br/>
            <p className="p-pre-pos-msg">Pos Mensaje</p>
            <ChatUser mensaje={ beforeAfter.after.message } mostrarList={ () => funcMostrarModalBox(beforeAfter.after.message.list) } />
        </>}
    </div>)
}