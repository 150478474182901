import { Outlet, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query'
import { customSwall } from '../components/CustomSwal';
import Spiner from '../components/Spiner';
import { getDatos } from '../providers/dataServer';
import Icons from '../components/Icons';
import moment from "moment/moment";
import { useState } from 'react';
import logo from '../assets/images/logo-novacreation-blanco.svg';
import isoLogo from '../assets/images/isotipo.svg';
import { isMobile } from 'react-device-detect';

function LinkOptionElementService({ tag, ruta }) {
    const navigate = useNavigate();
    return <div className='cont-info-servicio-opcion'>
        <svg xmlns="http://www.w3.org/2000/svg" height={ '60px' } viewBox="0 0 69.2 200"><g><polygon fill='var(--blanco)' points="69.2,102.5 0,102.5 0,0 5,0 5,97.5 69.2,97.5"/></g></svg>
        <p className='p-link-option-service' onClick={ () => navigate(ruta) } >{ tag }</p>
    </div>
}

function Servicio({ servicio, mostrarBarraLateral, funcMostrarBarra }) {

    const tipoServicio = servicio.servicio;
    const idBase64 = btoa(servicio.id.toString())
    const icon = (s) => {
        switch (s) {
            case 'Bot Whatsapp':
                return 'bot';
            case 'Mass Mail':
                return 'mailer';
        }
    }

    function clickLinkService() {
        if (servicio.estado === 0) {
            customSwall.fire({
                title: 'Servicio Caducado',
                text: 'Lo sentimos, aun no has renovado la suscripción a este servicio.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {
            if (!mostrarBarraLateral) {
                funcMostrarBarra()
            }
        }
        
        /*else if (tipoServicio === 'Bot Whatsapp') {
            navigate("/bot-whatsapp")
        } else if (tipoServicio === 'Mass Mail') {
            navigate("/mass-mail")
        }*/
    }
    
    return (<>
        <div className="cont-info-servicio bl-categoria-button" onClick={ clickLinkService }>
            <div className="info-serv-iz" >
                <div className="dash-cont-icon-config">
                    <Icons req={ icon(servicio.servicio) } fill="var(--blanco)" height="2em" viewBox={ '0 0 50 50' }/>
                </div>
                { mostrarBarraLateral && <div className="cont-nombre-servicio">
                    <h2>{ tipoServicio }</h2>
                    <p className='fecha-caduca-barra'>Caduca: { moment(servicio.dia_caduca).format('LL') }</p>
                </div>}
            </div>
        </div>
        { mostrarBarraLateral && <div className='cont-info-servicio-opciones'>
            <LinkOptionElementService tag={ 'Conversaciones' } ruta={ `/bot-whatsapp/${ idBase64 }` }/>
            <LinkOptionElementService tag={ 'Respuestas' } ruta={ `/bot-whatsapp/${ idBase64 }/lista-de-respuestas` }/>
            <LinkOptionElementService tag={ 'Arbol de Respuestas' } ruta={ `/bot-whatsapp/${ idBase64 }/arbol-del-bot` }/>
            <LinkOptionElementService tag={ 'Textos Activadores' } ruta={ `/bot-whatsapp/${ idBase64 }/textos-activadores` }/>
            <LinkOptionElementService tag={ 'Formularios' } ruta={ `/bot-whatsapp/${ idBase64 }/formularios` }/>
        </div>}
    </>);
}

function AppInit() {

    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('userData'))
    const [ mostrarBarraLateral, setMostratBarraLateral ] = useState(isMobile ? false : true)

    const { data: services = [], isLoading } = useQuery(["services"], () => getDatos('/user/getServices'), {
        onSuccess: (data) => {
            if (!!data.ok) {
                localStorage.removeItem('userData');
                window.location.reload()
            }
        }
    })

    function cerrarSession() {
        customSwall.fire({
            title: 'Cerrar Sesión',
            text: 'Deseas salir del sistema de administración?',
            icon: 'question',
            confirmButtonText: 'Cerrar Sesión',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('userData');
                window.location.reload()
            }
        });
    }

    const stylesBarraLateral = {
        //transform: mostrarBarraLateral ? 'translateX(0)' : 'translateX(-100%)',
        width: mostrarBarraLateral ? '300px' : '80px',
        overflowX: mostrarBarraLateral ? 'visible' : 'hidden',
    }

    return(
        <div className='cont-app'>
            { isLoading && <Spiner title={"Cargando..."} />}
            <div className='cont-barra-lateral' style={ stylesBarraLateral }>
                <button className='cont-cerrar-barra-lat' onClick={ () => setMostratBarraLateral(mostrarBarraLateral ? false: true) }>
                    <Icons req={  mostrarBarraLateral ? 'cerrar_menu' : 'menu' } fill="var(--blanco)" height="2em" viewBox={ '0 0 50 50' }/>
                </button>
                <div className='cont-section-barra cont-info-user'>
                    <img src={ mostrarBarraLateral ? logo : isoLogo } alt="logo nova creation"/>
                    {/*<h1 className='titulo-administrador'>Administrador de Tienda</h1>*/}
                    { mostrarBarraLateral && <p className='bl-nombre-admin'>Bienvenid@: { user.nombre } { user.apellido }</p>}
                    <button className='btn-salir-adm' onClick={ cerrarSession }>
                        <Icons req={ 'salir' } fill='var(--blanco)' />
                        {mostrarBarraLateral &&  <p>Salir</p>}
                    </button>
                </div>
                <div className='cont-item-bl'>
                    <div className='cont-opciones-item-bl'>
                        <button className='bl-categoria-button btn-options-bar' onClick={ () => navigate(`/`) }>
                            <Icons req={ 'inicio' } fill='var(--blanco)' />
                            {mostrarBarraLateral && <p className='p-text-button-bar'>Inicio</p>}
                        </button>
                    </div>
                </div>
                <div className='cont-item-bl'>
                    <p className={`bl-divisor-title ${ !mostrarBarraLateral && 'text-option-lateral-hidden'}`}>Servicios Disponibles</p>
                    <div className='cont-opciones-item-bl'>
                        {
                            services?.map((servicio) => {
                                return <Servicio key={ servicio.servicio } servicio={ servicio } mostrarBarraLateral={ mostrarBarraLateral } funcMostrarBarra={ () => setMostratBarraLateral(true) } />
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='cont-section' style={{ width: mostrarBarraLateral ? 'calc(100% -  300px)' : '100%' }}>
                <Outlet />
            </div>
        </div>
    )
}

export default AppInit