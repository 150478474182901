import { useNavigate, useParams } from "react-router-dom";
import Spiner from "../components/Spiner";
import { getDatos, mutarDatos } from "../providers/dataServer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customSwall } from "../components/CustomSwal";

export default function Formularios() {

    const queryClient = useQueryClient()
    const navigate = useNavigate();
    let params = useParams();
    const { data: formularios = [], isLoading } = useQuery(["formularios"], () => getDatos('/bot-whatsapp/getFormularios', { s: params["idService"] }))

    const { mutate : eliminar, isLoading: isEliminando } = useMutation({
        mutationFn: (datos) => mutarDatos({ruta: '/bot-whatsapp/delFormulario', parametros: datos})
    })

    function eliminarFormulario(id) {
        customSwall.fire({
            icon: 'warning',
            title: `Eliminar Formulario?`,
            text: `Estás segur@, esta acción no se puede revertir`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, Eliminar'
        }).then( response => {
            if (response.isConfirmed) {
                eliminar({ id: id }, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.invalidateQueries([ "formularios" ])
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'no se pudo eliminar',
                                showConfirmButton: true,
                            })
                        }
                    },
                })
            }
        })
    }

    return <div className="cont-lista-formularios">
        {(isLoading || isEliminando) && <Spiner/>}
        <div className="columna-completa header-formularios">
            <h1>Formularios</h1>
            <div className="cont-actions-header-forms">
                <button className="btn btn-azul" onClick={ () => navigate(`/bot-whatsapp/${ params["idService"] }/agregar-modificar-formulario`) }>Nuevo Formulario</button>
            </div>
        </div>
        <div className="cont-tabla-forms">
            <table id="tabla-productos" className="tabla-productos">
                <thead>
                    <tr>
                        <th width={'20px'} >#</th>
                        <th>Nombre</th>
                        <th>Descripcion</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        formularios?.map((p, index) => (
                            <tr key={p.id}>
                                <td width={'20px'}>{index + 1}</td>
                                <td >Este es el nombre</td>
                                <td >Descripcion</td>
                                <td className='td-acciones'>
                                    <button onClick={() => navigate(`/agregar-modificar-producto/${p.id}/${p.categoria}`)} className="btn btn-azul">Modificar</button>
                                    <button onClick={() => eliminarFormulario(p.id)} className="btn btn-rojo">Eliminar</button>
                                </td>
                            </tr>
                        )) ?? <p>No hay datos</p>}
                </tbody>
            </table>
        </div>
    </div>
}