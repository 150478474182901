import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { getDatos, mutarDatos } from '../api/fetchsApiQuerys';
import Icons from "../components/Icons"
import { customSwall } from "../components/CustomSwal";
import { MessagePrew } from "../bot/MessagePrew";
import moment from "moment";
import Spiner from "../components/Spiner";

function OptionRow({ idSeccion, idRow }) {
    const { register, formState: { errors } } = useFormContext()
    return(<div className="opcion-list">
        <div className="cont-row-title">
            <label htmlFor={ `rowTitle-${ idSeccion }-${ idRow }` }>Texto de la opción:</label>
            <input type="text" {...register(`rowTitle-${ idSeccion }-${ idRow }`, {
                required: {
                    value: true,
                    message: 'El texto de la opción es obligatorio'
                },
                minLength: {
                    value: 5,
                    message: 'El texto de la opción debe tener mínimo 5 caracteres'
                },
                maxLength: {
                    value: 24,
                    message: 'El texto de la opción debe tener máximo 24 caracteres'
                }
            })} placeholder={ `Ejemplo: Servicio # ${ idRow }` } />
            <span>{ !!errors[`rowTitle-${ idSeccion }-${ idRow }`] && errors[`rowTitle-${ idSeccion }-${ idRow }`].message }</span>
        </div>
        <div className="cont-row-title">
            <label htmlFor={ `rowDetalle-${ idSeccion }-${ idRow }` }>Descripción { '(opcional)' }:</label>
            <input type="text" {...register(`rowDetalle-${ idSeccion }-${ idRow }`, {
                maxLength: {
                    value: 72,
                    message: 'El texto de la opción debe tener máximo 72 caracteres'
                }
            })} placeholder="Descripción de esta opción" />
            <span>{ !!errors[`rowDetalle-${ idSeccion }-${ idRow }`] && errors[`rowDetalle$-${ idSeccion }-${ idRow }`].message }</span>
        </div>
    </div>)
}

function SeccionesListas({ idSeccion, numRows, validateRows }) {

    const { register, formState: { errors } } = useFormContext()

    function validateRowsT(e) {
        validateRows(e)
    }

    return (<div className={ `cont-seccion-${ idSeccion }` }>
                <div className="cont-title-num-seccion">
                    <div className="title-list-seccion">
                        <label htmlFor={ `title-l-${ idSeccion }` }>Titulo de sección { idSeccion }:</label>
                        <input type="text" {...register(`title-l-${ idSeccion }`, {
                            required: {
                                value: true,
                                message: 'El titulo de sección es obligatorio'
                            },
                            minLength: {
                                value: 2,
                                message: 'El titulo de sección debe tener mínimo 5 caracteres'
                            },
                            maxLength: {
                                value: 24,
                                message: 'El titulo de sección debe tener máximo 24 caracteres'
                            }
                        })} placeholder={ `Ejemplo: Lista de Servicios ${ idSeccion }` } />
                        <span>{ !!errors[`title-l-${ idSeccion }`] && errors[`title-l-${ idSeccion }`].message }</span>
                    </div>
                    <div className="cont-num-rows">
                        <label htmlFor={ `numRows${ idSeccion }` }>Número de opciones:</label>
                        <input type="number" {...register(`numRows${ idSeccion }`, {
                                onChange: validateRowsT,
                                min: {
                                    value: 1,
                                    message: 'Debes tener mínimo 1 opción'
                                },
                                max: {
                                    value: 10,
                                    message: 'Puedes crear máximo 10 opciones'
                                }
                            })}/>
                        <span>{ !!errors[`numRows${ idSeccion }`] && errors[`numRows${ idSeccion }`].message }</span>
                    </div>
                </div>
                <div className="cont-opcion-list">
                    <div className="cont-div-opcion-list">
                        { numRows[idSeccion - 1]?.map((r) => {
                            return(<OptionRow idSeccion={ idSeccion } idRow={ r } key={ r } />)
                        })}
                    </div>
                </div>
            </div>)
}

export default function AgregarModificarRespuesta() {
    const user = JSON.parse(localStorage.getItem('userData'))
    const [ modificar, setModificar ] = useState(false);
    const [ before, setBefore ] = useState(0);
    const [ after, setAfter ] = useState(0);
    const [ params ] = useSearchParams();
    const indFlowParam = params.get('idf') ?? 0
    const [ preview, setPreview ] = useState({
        tipo: 'text',
        fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
        texto: 'Aquí se mostrará tu mensaje',
        buttons: '{}',
        list: '{}',
        media: '{}',
        contacto: '{}',
        location: '{}',
        estado: "read",
        asesor: "Bot"
    })

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    //const [ requerido, setRequerido ] = useState({ is_req: 0, req: '', req_texto: ''});
    const [ idButtons, setIdButtons ] = useState(null)
    const [ idLists, setIdLists ] = useState(null)
    const [ numSecciones, setNumSecciones ] = useState(1)
    const [ numRows1, setNumRows1 ] = useState([1]);
    const [ numRows2, setNumRows2 ] = useState([]);
    const [ numRows3, setNumRows3 ] = useState([]);
    const [ numButtons, setNumButtons ] = useState(1)
    const navigate = useNavigate();
    const metods = useForm({
        defaultValues: {
            sections: 1,
            numRows1: 1,
            numRows2: 1,
            numRows3: 1,
            numBotones: 1,
            before: 0,
            after: 0,
            esRequerido: false
        },
        shouldUnregister: false
    })
    const { formState: { errors, isValid, isDirty } } = metods;
    //console.log(errors);
    //console.log(metods.watch())
    //[{"name":{"first_name":"Amorcito \u2665","formatted_name":"Amorcito \u2665"},"phones":[{"phone":"+593 99 976 3176","wa_id":"593999763176","type":"CELL"}]}]

    const { data: { flows: flowsList, config: { variables } = [] } = {}, isFetching } = useQuery(["flows"], () => getDatos('/bot-whatsapp/flows', { token: user.token, s: idBase64 }), {
        refetchOnWindowFocus: false,
        staleTime: 'Infinity',
        retryDelay: 1000,
        onSuccess: data => {
            if (parseInt(indFlowParam) > 0) {
                let arrayFlows = Object.values(data.flows)
                for(let i = 0; i < arrayFlows.length; i++) {
                    if (arrayFlows[i].id === parseInt(indFlowParam)) {
                        let f = arrayFlows[i]
                        setModificar(true)
                        let datosCarga = {value: false}
                        metods.setValue('type', f.type)
                        metods.setValue('name', f.name)
                        let media = JSON.parse(f.media)
                        metods.setValue('fileUrl', !!media.url? media.url : '')
                        if (f.type === 'text' ||f.type === 'list' || f.type === 'button' || f.type === 'image' || f.type === 'video') {
                            metods.setValue('text', JSON.parse(f.text).texto)
                        }
                        let requerido = JSON.parse(f.requerido)
                        //metods.setValue('req_texto', requerido.req_text)
                        //setRequerido(requerido)
                        metods.setValue('esRequerido', requerido.is_req === 1 ? true : false)
                        if (requerido.is_req === 1) {
                            metods.setValue('requerido', requerido.req)
                            metods.setValue('nombreRequerido', requerido.req_texto)
                        }
                        let befAf = JSON.parse(f.before_after)
                        setBefore(befAf.before)
                        setAfter(befAf.after)
                        if (f.type === 'button') {
                            datosCarga.value = true
                            let buttons = JSON.parse(f.buttons)
                            datosCarga['numButtons'] = buttons.length
                            let ids = []
                            buttons?.forEach((b, index) => {
                                metods.setValue(`button${ index + 1 }`, b.text)
                                ids[index] = b.id
                            })
                            metods.setValue('numBotones', buttons.length)
                            setNumButtons(buttons.length)
                            setIdButtons(ids)
                        }
                        if (f.type === 'list') {
                            datosCarga.value = true
                            let lista = JSON.parse(f.list)
                            metods.setValue('button', lista.button)
                            metods.setValue('sections', lista.sections.length)
                            setNumSecciones(lista.sections.length)
                            let numRows1 = []
                            let numRows2 = []
                            let numRows3 = []
                            let idsList = []
                            lista.sections?.forEach((s, indexS) => {
                                metods.setValue(`title-l-${ indexS + 1 }`, s.title)
                                s.rows?.forEach((r, index) => {
                                    metods.setValue(`rowTitle-${ indexS + 1 }-${ index + 1 }`, r.title)
                                    metods.setValue(`rowDetalle-${ indexS + 1 }-${ index + 1 }`, r.description)
                                    if (indexS === 0) {
                                        numRows1[index] = index +1
                                    }
                                    if (indexS === 1) {
                                        numRows2[index] = index +1
                                    }
                                    if (indexS === 2) {
                                        numRows3[index] = index +1
                                    }
                                    idsList.push({section: indexS, row: index, id: r.id})
                                })
                                metods.setValue(`numRows${ indexS + 1 }`, s.rows.length)
                            })
                            setNumRows1(numRows1)
                            setNumRows2(numRows2)
                            setNumRows3(numRows3)
                            datosCarga['numRows'] = [ numRows1, numRows2, numRows3 ]
                            setIdLists(idsList)
                        }
                        if (f.type === 'contacts') {
                            let c = JSON.parse(f.contact)
                            metods.setValue('contactNombre', c.name.formatted_name)
                            metods.setValue('contactNumber', c.phones[0].wa_id)
                        }
                        ordenar('', datosCarga)
                        break
                    }
                }
            }
        }
    })

    const { mutate, isLoading: isMutating } = useMutation(mutarDatos, {
        onSuccess: (data) => {
            if (data.ok) {
                customSwall.fire({
                    icon: 'success',
                    title: `Correcto!`,
                    text: `Se ${ modificar ? 'modificó' : 'agregó' } la respuesta correctamente`,
                    showConfirmButton: true,
                }).then( response => {
                    queryClient.invalidateQueries(["flows"])
                    navigate(`/bot-whatsapp/${ idBase64 }/lista-de-respuestas`)
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'ocurrio un error',
                    showConfirmButton: true,
                })
            }
        },
        onError: e => {
            customSwall.fire({
                icon: 'error',
                title: 'Error',
                text: 'ocurrio un error, contacta a soporte',
                showConfirmButton: true,
            })
            console.error(e)
        }
    })

    const onSubmit = async data => {
        let flowPreview = await ordenar('submit')
        if (modificar) {
            if (data.type === 'button') {
                idButtons?.forEach((b, index) => {
                    if (!!flowPreview.buttons[index]) flowPreview.buttons[index]['id'] = b
                })
            }
            if (data.type === 'list') {
                console.log(idLists)
                idLists?.forEach((l) => {
                    if (!!flowPreview.list.sections[l.section]) {
                        if (!!flowPreview.list.sections[l.section].rows[l.row]) {
                            flowPreview.list.sections[l.section].rows[l.row]['id'] = l.id
                        }
                    }
                })
            }
        }
        if (isValid) {
            let tipoRequerido = metods.watch('esRequerido') ? ((metods.watch('type') !== 'button' && metods.watch('type') !== 'list') ? metods.watch('requerido') : 'interactive') : ''
            let nombreRequerido = metods.watch('esRequerido') ? ((metods.watch('type') !== 'button' && metods.watch('type') !== 'list') ? metods.watch('nombreRequerido') : '') : ''
            let variablesTexto = [];
            if (!!data.text && (variables.length > 0)) {
                variables.forEach((v, key) => {
                    if (data.text.includes(`{{${v}}}`)) {
                        variablesTexto[key] = v
                    }
                });
            }
            let dataFlow = {
                id: indFlowParam,
                name: data.name,
                type: data.type,
                text: (data.type === 'text' || data.type === 'list' || data.type === 'button' || data.type === 'image' || data.type === 'video') ? { variables: variablesTexto, texto: data.text } : { variables: [], texto: ''},
                buttons: flowPreview.buttons,
                list: flowPreview.list,
                media: flowPreview.media,
                contact: flowPreview.contact,
                location: flowPreview.location,
                keywords: {},
                requerido: { is_req: metods.watch('esRequerido') ? 1 : 0, req: tipoRequerido, req_texto: nombreRequerido },
                before_after: { before:  before, after: after }
            }
            let datos = { 
                ruta: modificar ? '/bot-whatsapp/flows/modificar' : '/bot-whatsapp/flows/nuevo',
                parametros: {
                    token: user.token,
                    flow: JSON.stringify(dataFlow),
                    s: idBase64
                }
            }
            mutate(datos)
        }
    }

    const ordenar = async (accion = '', isEfect = {value: false}) => {
        let type = metods.watch('type')
        let lista = []
        let buttons = []
        let media = []
        let contacto = []
        let location = []
        if ((type === 'list' || type === 'button' || type === 'text') && accion === 'submit') {
            let msg = '';
            msg = (metods.watch('text').trim() === "") ? 'Este tipo de mensaje necesita un texto, no puede ir vacío' : ((type === 'text' && metods.watch('text').length > 4096) ? 'Este tipo de mensaje puede tener un máximo de 4096 caracteres' : (((type === 'list' || type === 'button') && metods.watch('text').length > 1024) ? 'Este tipo de mensaje puede tener un máximo de 1024 caracteres' : ''))
            if (msg !== "") {
                customSwall.fire({
                    title: 'Escribe un texto',
                    text: msg,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(r => {
                    return
                })
            }
        }

        if (type === 'button') {
            let countButtons = isEfect.value ? isEfect.numButtons : numButtons
            for (let b = 0; b < countButtons; b++) {
                buttons.push({text: metods.watch(`button${ b + 1 }`)})
            }
        }

        if (type === 'list') {
            let numRows = isEfect.value ? isEfect.numRows : [numRows1, numRows2, numRows3]
            lista = { button: metods.watch('button'), sections: [] }
            numRows?.forEach((nR, index) => {
                if (nR.length > 0) {
                    let section = {
                        title: metods.watch(`title-l-${ index + 1 }`),
                        rows: []
                    }
                    nR?.forEach((r, indexR) => {
                        section.rows.push({
                            title: metods.watch(`rowTitle-${ index + 1 }-${ indexR + 1 }`),
                            description: metods.watch(`rowDetalle-${ index + 1 }-${ indexR + 1 }`)
                        })
                    })
                    lista.sections.push(section)
                }
            })
        }

        if (type === 'contacts') {
            contacto = {"name":{"first_name":metods.watch('contactNombre'),"formatted_name":metods.watch('contactNombre')},"phones":[{"phone":'+'+metods.watch('contactNumber'),"wa_id":metods.watch('contactNumber'),"type":"CELL"}]}
        }

        if (type === 'location') {
            location = {"address":metods.watch('locationDir'),"latitude":metods.watch('latitude'),"longitude":metods.watch('longitude'),"name":metods.watch('locationNombre'),"url":metods.watch('locationUrl')}
        }

        //if (isValid) {}
        if (type === 'image' || type === 'video' || type === 'button' || type === 'audio') {
            media = { type: type, name: "", url: "" }
            if (isValidHttpUrl(metods.watch('fileUrl')) === true) {
                const isValidFile = await validateMediaFile(metods.watch('fileUrl'), type)
                if (isValidFile.isValid === true) {
                    media.url = metods.watch('fileUrl')
                    if (type === 'button') {
                        if (isValidFile.fileType === 'image/png' || isValidFile.fileType === 'image/jpeg') {
                            media.type = 'image'
                        } else if (isValidFile.fileType === 'video/mp4' || isValidFile.fileType === 'video/3gp') {
                            media.type = 'video'
                        }
                    }
                }
            }
        }
        setPreview({
            tipo: metods.watch('type'),
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: metods.watch('text'),
            buttons: JSON.stringify(buttons),
            list: JSON.stringify(lista),
            media: JSON.stringify(media),
            contacto: JSON.stringify(contacto),
            location: JSON.stringify(location),
            estado: "read",
            asesor: "Bot"
        })

        if (accion === 'submit') return { buttons: buttons, list: lista, media: media, contact: contacto, location: location } 
    }

    async function validateMediaFile(url, type) {
        let respuestaJson = { isValid: false, fileType: '' }
        try {
            const reponse = await fetch(url);
            //console.log(reponse.headers.get('Content-Type'));
            respuestaJson.fileType = reponse.headers.get('Content-Type')
            switch (type) {
                case 'sticker':
                    respuestaJson.isValid =  reponse.headers.get('Content-Type') === 'image/webp'
                    break
                case 'image':
                    respuestaJson.isValid =  reponse.headers.get('Content-Type') === 'image/png' || reponse.headers.get('Content-Type') === 'image/jpeg'
                    break
                case 'video':
                    respuestaJson.isValid = reponse.headers.get('Content-Type') === 'video/mp4' || reponse.headers.get('Content-Type') === 'video/3gp'
                    break
                case 'audio':
                    respuestaJson.isValid = reponse.headers.get('Content-Type') === 'audio/ogg' || reponse.headers.get('Content-Type') === 'audio/mpeg' || reponse.headers.get('Content-Type') === 'audio/aac' || reponse.headers.get('Content-Type') === 'audio/amr'
                    break
                case 'button':
                    respuestaJson.isValid = reponse.headers.get('Content-Type') === 'image/png' || reponse.headers.get('Content-Type') === 'image/jpeg' || reponse.headers.get('Content-Type') === 'video/mp4'
                    break
                default:
            }
            return respuestaJson
        } catch (error) {
            console.warn(error)
            return respuestaJson
        }
    }

    const validateNumButtons = e => {
        if(e.key==='.'){metods.setValue('numBotones', 1)}
        if(metods.watch('numBotones') === ''){metods.setValue('numBotones', 1)}

        if (parseInt(metods.watch('numBotones')) > 3) {
            metods.setValue('numBotones', 3)
        } else if (parseInt(metods.watch('numBotones')) < 1) {
            metods.setValue('numBotones', 1)
        }
        setNumButtons(parseInt(metods.watch('numBotones')))
    }
    const validateSecciones = e => {
        if(e.key==='.'){metods.setValue('sections', 1)}
        if(metods.watch('sections') === ''){metods.setValue('sections', 1)}

        if (parseInt(metods.watch('sections')) > 3) {
            metods.setValue('sections', 3)
        } else if (parseInt(metods.watch('sections')) < 1) {
            metods.setValue('sections', 1)
        }

        let secciones = parseInt(metods.watch('sections'))
        if (secciones === 1) {
            setNumSecciones(1)
            setNumRows2([])
            setNumRows3([])
        } else if (secciones === 2) {
            if (numRows1.length < 10) {
                setNumSecciones(2)
                if (numRows2.length === 0) {setNumRows2([1]); metods.setValue('numRows2', 1)}
                setNumRows3([])
            } else {
                customSwall.fire({
                    title: 'Límite de Opciones',
                    text: 'No es posible agregar otra sección, se alcanzó el límite de opciones: 10',
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(res => {
                    metods.setValue('sections', 1)
                    setNumSecciones(1)
                    setNumRows2([])
                    setNumRows3([])
                });
            }
        } else if (secciones === 3) {
            if ((numRows1.length + numRows2.length) < 10) {
                setNumSecciones(3)
                if (numRows2.length === 0) {setNumRows2([1]); metods.setValue('numRows2', 1)}
                if (numRows3.length === 0) {setNumRows3([1]); metods.setValue('numRows3', 1)}
            } else {
                customSwall.fire({
                    title: 'Límite de Opciones',
                    text: 'No es posible agregar otra sección, se alcanzó el límite de opciones: 10',
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                }).then(res => {
                    if (numRows2.length === 0) {
                        metods.setValue('sections', 1)
                        setNumSecciones(1)
                        setNumRows2([])
                        setNumRows3([])
                    } else {
                        metods.setValue('sections', 2)
                        setNumSecciones(2)
                        setNumRows2([1])
                        setNumRows3([])
                    }
                });
            }
        }
    }
    const validateRows = e => {
        let name = e.target.name
        if(e.key==='.'){metods.setValue(name, 1)}
        if(metods.watch(name) === ''){metods.setValue(name, 1)}

        let sumaRows = 0;
        if (name === 'numRows1') {
            sumaRows = numRows2.length + numRows3.length + parseInt(metods.watch(name))
        } else if (name === 'numRows2') {
            sumaRows = numRows1.length + numRows3.length + parseInt(metods.watch(name))
        } else if (name === 'numRows3') {
            sumaRows = numRows1.length + numRows2.length + parseInt(metods.watch(name))
        }

        if (sumaRows <= 10) {
            if (parseInt(metods.watch(name)) > 10) {
                metods.setValue(name, 10)
            } else if (parseInt(metods.watch(name)) < 1) {
                metods.setValue(name, 1)
            }
    
            let nums = [];
            for(let i = 0; i < parseInt(metods.watch(name)); i++) {
                nums[i] = i + 1
            }
            if (name === 'numRows1') {
                setNumRows1(nums)
            } else if (name === 'numRows2') {
                setNumRows2(nums)
            } else if (name === 'numRows3') {
                setNumRows3(nums)
            }
        } else {
            customSwall.fire({
                title: 'Límite de Opciones',
                text: 'En listas únicamente se permiten hasta 10 opciones en total',
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(res => {
                let newValue = 1;
                if (name === 'numRows1') {
                    newValue = 10 - numRows2.length - numRows3.length
                } else if (name === 'numRows2') {
                    newValue = 10 - numRows1.length - numRows3.length
                } else if (name === 'numRows3') {
                    newValue = 10 - numRows1.length - numRows2.length
                }
                metods.setValue(name, newValue)
                let nums = [];
                for(let i = 0; i < parseInt(metods.watch(name)); i++) {
                    nums[i] = i + 1
                }
                if (name === 'numRows1') {
                    setNumRows1(nums)
                } else if (name === 'numRows2') {
                    setNumRows2(nums)
                } else if (name === 'numRows3') {
                    setNumRows3(nums)
                }
            });
        }
    }

    const onChangeType = () => {
        metods.setValue('fileUrl', '')
        metods.setValue('text', '')
    }

    function getMsg(flowId) {
        if (flowId  === 0) {
            return {}
        }
        let flow = flowsList[flowId]
        return !!flow ? {
            tipo: flow.type,
            fecha: moment().format('YYYY-MM-DD HH:mm:ss'),
            texto: JSON.parse(flow.text).texto,
            buttons: flow.buttons,
            list: flow.list,
            media: flow.media,
            contacto: flow.contact,
            location: flow.location,
            estado: "read",
            asesor: "Bot"
        } : {}
    }

    function isValidHttpUrl(string) {
        let url;
        
        try {
          url = new URL(string);
        } catch (_) {
          return false;  
        }
      
        return url.protocol === "http:" || url.protocol === "https:";
    }

    return(<main className="main-chats colum">
        { (isFetching || isMutating) && <Spiner/> }
        <div className="ans-cont-flows">
            <div className="columna-completa cont-cab-flows">
                <h2>Agregar / Modificar Respuesta</h2>
                <div className="ans-cont-options">
                    { isDirty && <button className='btn btn-primario' form="f">Guardar</button>}
                    <Link to={`/bot-whatsapp/${ idBase64 }/lista-de-respuestas`} className='btn btn-secundario'><Icons req={ 'chat' } fill="var(--blanco)" height="1.2em"/> Lista de respuestas</Link>
                </div>
            </div>
        </div>
        <div className="cont-form-resp">
        {!!flowsList &&
        <FormProvider { ...metods }>
            <form id="f" onChange={ ordenar } onSubmit={metods.handleSubmit(onSubmit)} className="ans-formulario">
                <div className="cont-before-after">
                    <div className="cont-select-type-form">
                        <label htmlFor="before">Pre Mensaje:</label>
                        <select value={ before } {...metods.register("before", {
                            onChange: e => setBefore(parseInt(e.target.value))
                        })}>
                            <option value={ 0 }>Ninguno</option>
                            {
                                Object.values(flowsList)?.map((f) => {
                                    let mostrar = true
                                    let requerido = JSON.parse(f.requerido)
                                    let beforeAfter = JSON.parse(f.before_after)
                                    mostrar = (requerido.is_req === 1 || beforeAfter.before > 0 || beforeAfter.after > 0) ? false : true
                                    return mostrar && <option key={ f.id } value={ f.id }>{ f.name }</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="cont-select-type-form">
                        <label htmlFor="after">Pos Mensaje:</label>
                        <select value={ after } {...metods.register("after", {
                            onChange: e => setAfter(parseInt(e.target.value))
                        })}>
                            <option value={ 0 }>Ninguno</option>
                            {
                                Object.values(flowsList)?.map((f) => {
                                    let mostrar = true
                                    let requerido = JSON.parse(f.requerido)
                                    let beforeAfter = JSON.parse(f.before_after)
                                    mostrar = (requerido.is_req === 1 || beforeAfter.before > 0 || beforeAfter.after > 0) ? false : true
                                    return mostrar && <option key={ f.id } value={ f.id }>{ f.name }</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                {(metods.watch('type') === 'location' || metods.watch('type') === 'contacts' || metods.watch('type') === 'document') ? <></> : <div className="cont-before-after">
                    <div className='cont-checkbox-config-anw'>
                        <label className="switch">
                            <input type="checkbox" {...metods.register("esRequerido")} />
                            <span className="slider round"></span>
                        </label>
                        <p>Respuesta Obligatoria</p>
                    </div>
                    {(metods.watch('esRequerido') && metods.watch('type') !== 'button' && metods.watch('type') !== 'list') &&
                     <div className="cont-select-type-form select-req">
                        <label htmlFor="requerido">Que debe responder el usuario?:</label>
                        <select {...metods.register("requerido", {
                            onChange: e => {
                                if (e.target.value === 'image') {
                                    metods.setValue('nombreRequerido', 'pago')
                                } else if (e.target.value === 'text') {
                                    metods.setValue('nombreRequerido', 'nombres')
                                }
                            }
                        })}>
                            <option value={ 'text' }>Un texto</option>
                            <option value={ 'image' }>Una imagen</option>
                        </select>
                    </div>}
                    {(metods.watch('esRequerido') && metods.watch('type') !== 'button' && metods.watch('type') !== 'list') &&
                     <div className="cont-select-type-form select-req">
                        <label htmlFor="nombreRequerido">Que debe responder el usuario?:</label>
                        <select {...metods.register("nombreRequerido", {
                            required: true
                        })}>
                            <option value={ '' }>Elige una opcion</option>
                            {(metods.watch('requerido') === 'text') && <option value={ 'nombres' }>Nombre y Apellido</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'cedula' }>Numero de Cedula</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'cedula_r' }>Numero de Cedula Rec</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'contrato_r' }>Numero de Contrato Rec</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'contrato' }>Numero de Contrato</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'reclamo' }>Reclamo</option>}
                            {(metods.watch('requerido') === 'image') && <option value={ 'pago' }>Comprobante de pago</option>}
                            {(metods.watch('requerido') === 'image') && <option value={ 'foto' }>Una Foto</option>}
                            {(metods.watch('requerido') === 'image') && <option value={ 'foto_r' }>Una Foto Rec</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'reserva_nombres' }>Reserva Nombres y Apellidos</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'reserva_dia' }>Reserva dia</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'reserva_numero_personas' }>Reserva Numero Personas</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'reserva_anombre' }>Reserva A nombre de quien</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'reserva_hora' }>Reserva Hora</option>}
                            {(metods.watch('requerido') === 'text') && <option value={ 'reserva_especial' }>Reserva Especial</option>}
                        </select>
                    </div>}
                </div>}
                <div className="cont-select-name">
                    <div className="cont-select-type-form">
                        <label htmlFor="type">Tipo de respuesta:</label>
                        <select {...metods.register("type", {
                            onChange: onChangeType
                        })}>
                            <option value={ 'text' }>Texto</option>
                            <option value={ 'button' }>Botones</option>
                            <option value={ 'list' }>Lista</option>
                            <option value={ 'image' }>Imagen</option>
                            <option value={ 'video' }>Video</option>
                            <option value={ 'audio' }>Audio</option>
                            <option value={ 'document' }>Documento</option>
                            <option value={ 'location' }>Ubicación</option>
                            <option value={ 'contacts' }>Contacto</option>
                        </select>
                    </div>
                    <div className="cont-nombre-msg-form">
                        <label htmlFor="nombre">Nombre del mensaje:</label>
                        <input type="text" {...metods.register("name", {
                            required: {
                                value: true,
                                message: 'El nombre de esta respuesta es obligatorio'
                            },
                            minLength: {
                                value: 5,
                                message: 'El nombre debe tener mínimo 5 caracteres'
                            },
                            maxLength: {
                                value: 50,
                                message: 'El nombre debe tener máximo 50 caracteres'
                            }
                        })} placeholder="Ejemplo: Respuesta Servicio Técnico" />
                        <span>{ !!errors['name'] && errors['name'].message }</span>
                    </div>
                </div>
                { (metods.watch('type') === 'image' || metods.watch('type') === 'video' || metods.watch('type') === 'button' || metods.watch('type') === 'audio') &&
                <div className="form-seccion-items">
                    <div className="media-form-item">
                        <label htmlFor="fileUrl">Url del Archivo</label>
                        <input type="text" {...metods.register('fileUrl', {
                            required: {
                                value: metods.watch('type') !== 'button' ? true : false,
                                message: 'Escribe la dirección web del archivo'
                            },
                            validate: async value => {
                                if (metods.watch('type') !== 'button') {
                                    if (isValidHttpUrl(value) === true) {
                                        const isValidFile = await validateMediaFile(value, metods.watch('type'))
                                        if (isValidFile.isValid === true) {
                                            return true
                                        }
                                        return 'El link proporcionado no es del tipo correcto o soportado'
                                    }
                                    return 'El link proporcionado no es válido'
                                } else {
                                    if (metods.watch('type') === 'button' && value !== '') {
                                        if (isValidHttpUrl(value) === true) {
                                            const isValidFile = await validateMediaFile(value, metods.watch('type'))
                                            if (isValidFile.isValid === true) {
                                                return true
                                            }
                                            return 'El link proporcionado no es del tipo correcto o soportado'
                                        }
                                        return 'El link proporcionado no es válido'
                                    } else {
                                        return true
                                    }
                                }
                            }
                        })}
                        placeholder={ `${ metods.watch('type') === 'button' ? "(Opcional)" : "" } Ejemplo: ${ metods.watch('type') === 'audio' ? 'https://www.linkdelaudio.com/audio.ogg' : 'https://www.linkdelaimagen.com/imagen.jpg'}` }/>
                        <span>{ !!errors['fileUrl'] && errors['fileUrl'].message }</span>
                    </div>
                </div>}
                { (metods.watch('type') === 'text' || metods.watch('type') === 'list' || metods.watch('type') === 'button' || metods.watch('type') === 'image' || metods.watch('type') === 'video') &&
                <div className="cont-text-form">
                    <label htmlFor="text">Texto del mensaje:</label>
                    <textarea {...metods.register("text", {
                        required: {
                            value: true,
                            message: 'Este mensaje necesita un texto válido'
                        },
                        validate: value => {
                            if (value.trim() === "") {
                                return 'Este mensaje necesita un texto válido'
                            }
                            if (metods.watch('type') === 'text' && value.length > 4096) {
                                return 'Este tipo de mensaje puede tener un máximo de 4096 caracteres'
                            }
                            if ((metods.watch('type') === 'list' || 
                            metods.watch('type') === 'button' ||
                            metods.watch('type') === 'image' ||
                            metods.watch('type') === 'video') && value.length > 1024) {
                                return 'Este tipo de mensaje puede tener un máximo de 1024 caracteres'
                            }
                            return true
                        }
                    })} placeholder="Escribe tu mensaje aquí" rows={ 6 }></textarea>
                    <span>{ !!errors['text'] && errors['text'].message }</span>
                    { (variables.length > 0) && <p>Variables disponibles: {
                         variables?.map((v) => {
                            return ` {{${v}}} `
                        })
                        }</p>}
                </div>}
                { metods.watch('type') === 'list' && <div className="cont-form-list-form">
                    <div className="cont-button-list-form">
                        <label htmlFor="button">Texto del botón:</label>
                        <input type="text" {...metods.register("button", {
                            required: {
                                value: true,
                                message: 'Texto del botón es obligatorio'
                            },
                            minLength: {
                                value: 2,
                                message: 'El botón debe tener mínimo 2 caracteres'
                            },
                            maxLength: {
                                value: 20,
                                message: 'El botón debe tener máximo 20 caracteres'
                            }
                        })} placeholder="Ejemplo: Mostrar Lista" />
                        <span>{ !!errors['button'] && errors['button'].message }</span>
                    </div>
                    <div className="cont-list-secciones">
                        <div className="num-secciones">
                            <label htmlFor="sections">Secciones de la lista</label>
                            <input type="number" {...metods.register("sections", {
                                    onChange: validateSecciones,
                                    min: {
                                        value: 1,
                                        message: 'Debes tener mínimo 1 sección'
                                    },
                                    max: {
                                        value: 3,
                                        message: 'Puedes crear máximo 3 secciones'
                                    }
                                })}/>
                            <span>{ !!errors['sections'] && errors['sections'].message }</span>
                        </div>
                        <SeccionesListas idSeccion={ 1 } numRows={ [numRows1, numRows2, numRows3] } validateRows={ (e) => validateRows(e) } />
                        { numSecciones === 2 && <SeccionesListas idSeccion={ 2 } numRows={ [numRows1, numRows2, numRows3] } validateRows={ (e) => validateRows(e) } /> }
                        { numSecciones === 3 && <>
                        <SeccionesListas idSeccion={ 2 } numRows={ [numRows1, numRows2, numRows3] } validateRows={ (e) => validateRows(e) } />
                        <SeccionesListas idSeccion={ 3 } numRows={ [numRows1, numRows2, numRows3] } validateRows={ (e) => validateRows(e) } />
                        </> }
                        
                    </div>
                </div>}
                { metods.watch('type') === 'button' && <div className="cont-form-list-form cont-items-form-border">
                    <div className="cont-item-form num-botones">
                        <label htmlFor="numBotones">Cantidad de botones:</label>
                        <input type="number" {...metods.register("numBotones", {
                                onChange: validateNumButtons,
                                min: {
                                    value: 1,
                                    message: 'Debes tener mínimo 1 sección'
                                },
                                max: {
                                    value: 3,
                                    message: 'Puedes crear máximo 3 secciones'
                                }
                            })}/>
                        <span>{ !!errors['numBotones'] && errors['numBotones'].message }</span>
                    </div>
                    <div className="cont-item-form">
                        <label htmlFor="button1">Texto del botón 1:</label>
                        <input type="text" {...metods.register("button1", {
                            required: {
                                value: true,
                                message: 'Texto del botón es obligatorio'
                            },
                            minLength: {
                                value: 2,
                                message: 'El botón debe tener mínimo 2 caracteres'
                            },
                            maxLength: {
                                value: 20,
                                message: 'El botón debe tener máximo 20 caracteres'
                            }
                        })} placeholder="Ejemplo: Mostrar Lista" />
                        <span>{ !!errors['button1'] && errors['button1'].message }</span>
                    </div>
                    { numButtons === 2 &&
                        <div className="cont-item-form">
                            <label htmlFor="button2">Texto del botón 2:</label>
                            <input type="text" {...metods.register("button2", {
                                required: {
                                    value: true,
                                    message: 'Texto del botón es obligatorio'
                                },
                                minLength: {
                                    value: 2,
                                    message: 'El botón debe tener mínimo 2 caracteres'
                                },
                                maxLength: {
                                    value: 20,
                                    message: 'El botón debe tener máximo 20 caracteres'
                                }
                            })} placeholder="Ejemplo: Mostrar Lista" />
                            <span>{ !!errors['button2'] && errors['button2'].message }</span>
                        </div>
                    }
                    { numButtons === 3 && <>
                        <div className="cont-item-form">
                            <label htmlFor="button2">Texto del botón 2:</label>
                            <input type="text" {...metods.register("button2", {
                                required: {
                                    value: true,
                                    message: 'Texto del botón es obligatorio'
                                },
                                minLength: {
                                    value: 2,
                                    message: 'El botón debe tener mínimo 2 caracteres'
                                },
                                maxLength: {
                                    value: 20,
                                    message: 'El botón debe tener máximo 20 caracteres'
                                }
                            })} placeholder="Ejemplo: Mostrar Lista" />
                            <span>{ !!errors['button2'] && errors['button2'].message }</span>
                        </div>
                        <div className="cont-item-form">
                        <label htmlFor="button3">Texto del botón 3:</label>
                        <input type="text" {...metods.register("button3", {
                            required: {
                                value: true,
                                message: 'Texto del botón es obligatorio'
                            },
                            minLength: {
                                value: 2,
                                message: 'El botón debe tener mínimo 2 caracteres'
                            },
                            maxLength: {
                                value: 20,
                                message: 'El botón debe tener máximo 20 caracteres'
                            }
                        })} placeholder="Ejemplo: Mostrar Lista" />
                        <span>{ !!errors['button3'] && errors['button3'].message }</span>
                    </div>
                    </>}
                </div>}
                { metods.watch('type') === 'contacts' && <div className="cont-form-contacts">
                    <div className="cont-item-form">
                        <label htmlFor="contactNombre">Nombre del contacto:</label>
                        <input type="text" {...metods.register("contactNombre", {
                            required: {
                                value: true,
                                message: 'El nombre del contacto es obligatorio'
                            },
                            minLength: {
                                value: 2,
                                message: 'El nombre del contacto debe tener mínimo 2 caracteres'
                            },
                            maxLength: {
                                value: 40,
                                message: 'El nombre del contacto debe tener máximo 40 caracteres'
                            }
                        })} placeholder="Ejemplo: Agente de Ventas 1" />
                        <span>{ !!errors['contactNombre'] && errors['contactNombre'].message }</span>
                    </div>
                    <div className="cont-item-form">
                        <label htmlFor="contactNumber">Número:</label>
                        <input type="text" {...metods.register("contactNumber", {
                            required: {
                                value: true,
                                message: 'El número es obligatorio'
                            },
                            pattern : {
                                value: /^[0-9]/,
                                message: 'Formato incorrecto, ejemplo: 593978456621'
                            },
                            minLength: {
                                value: 12,
                                message: 'El número debe tener minimo 12 caracteres'
                            },
                            maxLength: {
                                value: 20,
                                message: 'El número debe tener máximo 20 caracteres'
                            }
                        })} placeholder="Formato: 593978456621" />
                        <span>{ !!errors['contactNumber'] && errors['contactNumber'].message }</span>
                    </div>
                </div>}
                { metods.watch('type') === 'location' && <div className="cont-form-contacts">
                    <div className="cont-item-form">
                        <label htmlFor="locationNombre">Nombre del establecimiento, edificio o lugar:</label>
                        <input type="text" {...metods.register("locationNombre", {
                            required: {
                                value: true,
                                message: 'El nombre de la ubicación es obligatorio'
                            },
                            minLength: {
                                value: 2,
                                message: 'El nombre de la ubicación debe tener mínimo 2 caracteres'
                            },
                            maxLength: {
                                value: 30,
                                message: 'El nombre de la ubicación debe tener máximo 30 caracteres'
                            }
                        })} placeholder="Ejemplo: Restaurante La Sazón" />
                        <span>{ !!errors['locationNombre'] && errors['locationNombre'].message }</span>
                    </div>
                    <div className="cont-item-form">
                        <label htmlFor="locationDir">{'Dirección (Calles o Avenidas):'}</label>
                        <input type="text" {...metods.register("locationDir", {
                            required: {
                                value: true,
                                message: 'La dirección es obligatoria'
                            },
                            minLength: {
                                value: 5,
                                message: 'La dirección debe tener minimo 5 caracteres'
                            },
                            maxLength: {
                                value: 100,
                                message: 'La dirección debe tener máximo 100 caracteres'
                            }
                        })} placeholder="Quito, Av. Primera N0-123 y calle 4" />
                        <span>{ !!errors['locationDir'] && errors['locationDir'].message }</span>
                    </div>
                    <div className="cont-item-form">
                        <label htmlFor="longitude">Coordenadas Longitud</label>
                        <input type="text" {...metods.register("longitude", {
                            required: {
                                value: true,
                                message: 'La longitud geográfica es obligatoria'
                            },
                            minLength: {
                                value: 2,
                                message: 'La longitud geográfica debe tener minimo 2 caracteres'
                            },
                            maxLength: {
                                value: 50,
                                message: 'La longitud geográfica debe tener máximo 50 caracteres'
                            }
                        })} placeholder="-78.487608" />
                        <span>{ !!errors['longitude'] && errors['longitude'].message }</span>
                    </div>
                    <div className="cont-item-form">
                        <label htmlFor="latitude">Coordenadas Latitud</label>
                        <input type="text" {...metods.register("latitude", {
                            required: {
                                value: true,
                                message: 'La Latitud geográfica es obligatoria'
                            },
                            minLength: {
                                value: 2,
                                message: 'La Latitud geográfica debe tener minimo 2 caracteres'
                            },
                            maxLength: {
                                value: 50,
                                message: 'La Latitud geográfica debe tener máximo 50 caracteres'
                            }
                        })} placeholder="-0.161715" />
                        <span>{ !!errors['latitude'] && errors['latitude'].message }</span>
                    </div>
                </div>}
            </form>
            </FormProvider> }
            <div className="ans-cont-prevw-list">
                { !!flowsList && <MessagePrew message={ preview } isModal={ false } beforeAfter={ {before: { value: before > 0, message: before > 0 ? getMsg(before) : {} }, after: { value: after > 0, message: after > 0 ? getMsg(after) : {} }} } /> }
            </div>
        </div>
    </main>)
}