import { Link, useNavigate } from 'react-router-dom'
import { customSwall } from '../components/CustomSwal';
import Icons from '../components/Icons';
import 'moment/locale/es';

function ConfigurarServicio({ servicio }) {
    const navigate = useNavigate();

    function setearBot() {
        customSwall.fire({
            title: 'Confirmación',
            text: 'Estás seguro que la información es correcta?, esta acción no se puede revertir y en caso de ser errónea el servicio quedará inactivo hasta que la información sea la correcta',
            icon: 'question',
            confirmButtonText: 'Actualizar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'
        });
    }

    let contConfigService = <></>
    if (servicio.servicio === 'Bot Whatsapp') {
        contConfigService = <>{/*
            <div className="s-item-config">
                <label htmlFor='token_ws'>Token Wts</label>
                <input id='token_ws' type='text' placeholder='XFAGgKvgufiUB ...'/>
            </div>
            <div className="s-item-config">
                <label htmlFor="id_wts_bussines">Id Wts Bussines</label>
                <input id='id_wts_bussines' type='text' placeholder='112233445566778'/>
            </div>
            <div className="s-item-config">
                <label htmlFor="version">Version</label>
                <input id='version' type='text' placeholder='v16.0'/>
            </div>
            <div className="s-item-config">
                <label htmlFor="token_webhook">Token Webhook</label>
                <input id='token_webhook' type='text' placeholder='xrtfddf8fgh54df14'/>
            </div>
            <button onClick={ setearBot } className='btn btn-primario'> Actualizar Configuración</button>*/}
            <Link to="/bot-whatsapp/arbol-del-bot" className='btn btn-secundario'><Icons req={ 'chat' } fill="var(--blanco)" height="1.2em"/> Arbol del bot</Link>
            <Link to="/bot-whatsapp/lista-de-respuestas" className='btn btn-secundario'><Icons req={ 'chat' } fill="var(--blanco)" height="1.2em"/> Lista de Respuestas</Link>
        </>
    }
    return(<div className="cont-config-service">
        <div className="cont-items-config">
            { contConfigService }
        </div>
    </div>)
}

export default function Dashboard() {

    return(<main className="main-outlet">
        <p>MAIN</p>
    </main>)
}