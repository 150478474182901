import Icons from "./Icons";

function ModalBox({ close, title, children, isPreview=false }) {
    return (
        <div className={ `cont-modal-box${ isPreview ? ' modal-box-prev' : ''}` }>
            <div className="cont-modal-box-header">
                <h1>{ title }</h1>
                <button onClick={ close }><Icons req={ 'close_x' } fill="var(--blanco)" /></button>
            </div>
            <div className="modal-box-body">
                { children }
            </div>
        </div>
    );
}

export default ModalBox;