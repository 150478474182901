import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Icons from "../components/Icons";
import { urlServer } from "../providers/serverProvider";

const urlRes = `${ urlServer }/helpers/files.php`;
//const urlRes = 'http://localhost/botwhatsapp/helpers/files.php';

function ListView({ lista }) {
    let list = JSON.parse(lista);
    return (
        <div className="cont-lists">
            {
                list.sections.map((l, index) => {
                    return (
                        <div key={ `lista_${index}` } className="list-section">
                            <h2>{ l.title }</h2>
                            <div className="cont-items-list">
                                {
                                    l.rows.map((r, index) => {
                                        return (<div key={ `item_${index}` } className="cont-p-list">
                                            <p className="p-1">• { r.title }</p>
                                            <p className="p-2">{ r.description }</p>
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

function Location({ latitude, longitude }) {
    return(<>
        <p className="type-msj">Ubicación:</p>
        <p><b>Latitud: </b>{ latitude }</p>
        <p><b>Longitude: </b>{ longitude }</p>
        <Link className="link-location" to={ `https://www.google.com/maps/search/?api=1&query=${ latitude },${ longitude }` } target="blank"><Icons req={ 'eye' }/> Ver en Google Maps</Link>
    </>);
}

function Image({ img }) {
    //console.log(img)
    let imagen = 'https://static.vecteezy.com/system/resources/previews/004/683/276/non_2x/icon-broken-image-line-style-simple-illustration-editable-stroke-free-vector.jpg'
    let objImage = null
    try {
        objImage = JSON.parse(img)
        if (objImage.url !== '') {
            imagen = objImage.url
        } else if (objImage.name !== '') {
            imagen = `${ urlRes }?f=${ objImage.name.replace('./recursos/','') }`
        }
    } catch (error) {
        imagen = `${ urlServer }${ img.replace('./','/') }`
    }
    return(<>
        <img src={ imagen } className="img-msj" />
    </>);
}

function Sticker({ img }) {
    //console.log(img)
    let imagen = 'https://static.vecteezy.com/system/resources/previews/004/683/276/non_2x/icon-broken-image-line-style-simple-illustration-editable-stroke-free-vector.jpg'
    let objImage = null
    try {
        objImage = JSON.parse(img)
        if (objImage.url !== '') {
            imagen = objImage.url
        } else if (objImage.name !== '') {
            imagen = `${ urlRes }?f=${ objImage.name.replace('./recursos/','') }`
        }
    } catch (error) {
        imagen = `${ urlServer }${ img.replace('./','/') }`
    }
    return(<>
        <img src={ imagen } className="sticker-msj" />
    </>);
}

function Audio({ media }) {
    const audioRef = useRef(null);
    const [ source, setSource ] = useState()

    const updateSong = (source) => {
        setSource(source);
        if(audioRef.current){
            //audioRef.current.pause();
            audioRef.current.load();
            //audioRef.current.play();
        }
    }
    useEffect(() => {
        let objAudio = null
        let newSource = ''
        //console.log(media)
        try {
            objAudio = JSON.parse(media)
            if (objAudio.url !== '') {
                newSource = objAudio.url
            } else if (objAudio.name !== '') {
                newSource = `${ urlRes }?f=${ objAudio.name.replace('./recursos/','') }`
            }
        } catch {
            newSource = `${ urlRes }?f=${ media.replace('./recursos/','') }`
        }
        updateSong(newSource)
    },[media])

    return(<>
        <audio ref={audioRef} controls>
            <source src={ source } />
            Your browser does not support the audio element.
        </audio>  
    </>);
}

function Video({ video }) {
    let hayVideo = true
    let file = 'https://static.vecteezy.com/system/resources/previews/004/683/276/non_2x/icon-broken-image-line-style-simple-illustration-editable-stroke-free-vector.jpg'
    let objVideo = null
    try {
        objVideo = JSON.parse(video)
        if (objVideo.url !== '') {
            file = objVideo.url
        } else if (objVideo.name !== '') {
            file = `${ urlRes }?f=${ objVideo.name.replace('./recursos/','') }`
        } else {
            hayVideo = false
        }
    } catch {
        file = `${ urlRes }?f=${ video.replace('./recursos/','') }`
    }
    return(<>
    {
        hayVideo ?
        <video src={ file } controls preload="none" className="video-msj">
            Tu navegador no es compatible con la reproducción de videos.
        </video> :
        <img src={ file } className="sticker-msj" />
    }
    </>);
}

function Document({ file, nombre }) {
    const fileLink = file.replace('./recursos/','');
    return(<>
        <Link to={ `${ urlRes }?f=${ fileLink }` } download target="_blank" className="document-msj"><Icons req={ 'download' } fill="#ffffff"/> { nombre }</Link>
    </>);
}

function Contacts({ cname, cnumero, contact='{}' }) {
    let exportContact = <></>
    if (contact !== '{}') {
        let c = JSON.parse(contact)
        console.log(c)
        exportContact = <>
            <p className="type-msj">Contacto:</p>
            <p><b>Nombre: </b>{ c.name.formatted_name }</p>
            {c.phones?.map((p, index) => {
                return <p key={ `contact${index}` }><b>Número: </b>{ p.phone }</p>
            })}
        </>
    } else {
        exportContact = <>
            <p className="type-msj">Contacto:</p>
            <p><b>Nombre: </b>{ cname }</p>
            <p><b>Número: </b>{ cnumero }</p>
        </>
    }
    return exportContact
}

export { ListView, Location, Image, Video, Sticker, Audio, Document, Contacts }