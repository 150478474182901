import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './assets/css/App.css';
import './assets/css/NewApp.css';
import './assets/css/media-querys.css';
import BodyChatsDisplay from './bot/BodyChatsDisplay';
import MassMailer from './pages/MassMailer';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import LogReg from './pages/LogReg';
import Dashboard from './pages/Dashboard';
import BotWhatsapp from './pages/BotWhatsapp';
import AnswerConfig from './bot/AnswerConfig';
import ListaRespuestas from './bot/ListaRespuestas';
import AgregarModificarRespuesta from './bot/AgregarModificarRespuesta';
import AppInit from './pages/AppInit';
import Formularios from './bot/Formularios';
import AgregarModificarFormulario from './bot/AgregarModificarFormulario';
import TextosActivadores from './bot/TextosActivadores';

function App() {
  
  const [ usuarioLogeado, setUsuarioLogeado ] = useState(false)

  useEffect(() => {
    const datosUsuario = !!localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    if (!!datosUsuario) {
      setUsuarioLogeado(true)
    }
  }, [])

  return (<>
      <Routes>
        <Route path="/" element={ usuarioLogeado ? <ProtectedRoutes><AppInit/></ProtectedRoutes> : <LogReg/> }>
          <Route index element={ <Dashboard /> }/>
          <Route path='/bot-whatsapp/:idService' element={<ProtectedRoutes><BotWhatsapp/></ProtectedRoutes>}>
            <Route index element={<BodyChatsDisplay/>} />
            <Route path='arbol-del-bot' element={ <AnswerConfig/> } />
            <Route path='lista-de-respuestas' element={ <ListaRespuestas/> } />
            <Route path='agregar-modificar-respuesta' element={ <AgregarModificarRespuesta/> } />
            <Route path='formularios' element={ <Formularios/> } />
            <Route path='textos-activadores' element={ <TextosActivadores/> } />
            <Route path='agregar-modificar-formulario' element={ <AgregarModificarFormulario/> } />
          </Route>
        </Route>
        {/*<Route path='/inicio' element={
          <ProtectedRoutes><Dashboard/></ProtectedRoutes>
        }></Route>

        <Route path='/mass-mail' element={
          <ProtectedRoutes>
            <MassMailer/>
          </ProtectedRoutes>
        } />
      <Route path='*' element={ <Navigate replace to="/"/> } />*/}
      </Routes>
    </>);
}

export default App;
