export default function Vistos({ estado }) {
    let visto = <svg viewBox="0 0 16 15" width="16" height="15" className=""><path fill="currentColor" d="m10.91 3.316-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>;
    switch (estado) {
        case 'sent':
            visto = <svg viewBox="0 0 16 15" width="16" height="15"className="visto-svg"><path fill="currentColor" d="m10.91 3.316-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>;
            break;
        case 'delivered':
            visto = <svg viewBox="0 0 16 15" width="16" height="15" className="visto-svg"><path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>;
            break;
        case 'read':
            visto = <svg viewBox="0 0 16 15" width="16" height="15" className="visto-svg"><path fill="#00aeff" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>;
            break;
        case 'failed':
            visto = <svg viewBox="0 0 16 15" width="16" height="15" className="visto-svg"><path fill="#FF0000" d="M8,13.6c-3.4,0-6.1-2.7-6.1-6.1S4.6,1.4,8,1.4s6.1,2.7,6.1,6.1S11.4,13.6,8,13.6z M8,2.1c-3,0-5.4,2.4-5.4,5.4 S5,12.9,8,12.9s5.4-2.4,5.4-5.4S11,2.1,8,2.1z M8.9,7.5L11,5.4c0.3-0.3,0.3-0.7,0-0.9s-0.7-0.3-0.9,0L8,6.6L5.9,4.5 C5.7,4.3,5.3,4.3,5,4.5S4.8,5.2,5,5.4l2.1,2.1L5,9.6c-0.3,0.3-0.3,0.7,0,0.9s0.7,0.3,0.9,0L8,8.4l2.1,2.1c0.3,0.3,0.7,0.3,0.9,0 s0.3-0.7,0-0.9L8.9,7.5z"/></svg>;
            break;
    }
    return (
        <span>
            { visto }
        </span>
    );
}